import axios from 'axios';
import errorInterceptor from './ErrorInterceptors';

const Api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 1000 * 60,
});

Api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(response => response, errorInterceptor);

const Get = async (url, params, config = {}) => {
  try {
    const { data } = await Api.get(url, { params, ...config });
    return data;
  } catch (error) {
    throw error;
  }
};

const Post = async (url, postData, config = {}) => {
  try {
    const { data } = await Api.post(url, postData, config);
    return data;
  } catch (error) {
    throw error;
  }
};

const Put = async (url, putData) => {
  try {
    const { data } = await Api.put(url, putData);
    return data;
  } catch (error) {
    throw error;
  }
};

const Delete = async (url) => {
  try {
    const { data } = await Api.delete(url);
    return data;
  } catch (error) {
    throw error;
  }
};

Api.Get = Get;
Api.Post = Post;
Api.Put = Put;
Api.Delete = Delete;

export default Api;
