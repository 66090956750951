import React, { useState } from 'react'
import MainLayout from '../../layouts/MainLayout'
import { LeftOutlined } from '@ant-design/icons';
import { Select, Button } from 'antd';
import CSV from '../../assets/download-csv.png';
import { useNavigate } from 'react-router-dom';
import CustomDatePicker from '../CustomDatePicker';
import InOutsTable from './InOutsTable';
import useFilter from '../useFilter';

export default function InOuts() {
  const navigate = useNavigate();
  const data = [ {
    name: "Mike",
    project: "Workotick",
    duration: "10:00",
    in: "10:00 AM",
    out: "10:00 PM",
    department: "Development" 
  }, {
    name: "John",
    project: "paybag",
    duration: "10:00",
    in: "10:00 AM",
    out: "10:00 PM",
    department: "Design" 
  }, {
    name: "Batool",
    project: "Workotick",
    duration: "10:00",
    in: "10:00 AM",
    out: "10:00 PM",
    department: "QA" 
  }, {
    name: "Ahsan",
    project: "paybag",
    in: "10:00 AM",
    out: "10:00 PM",
    duration: "10:00",
    department: "Development" 
  }, {
    name: "Mike",
    project: "Workotick",
    duration: "10:00",
    in: "10:00 AM",
    out: "10:00 PM",
    department: "Development" 
  }, {
    name: "Mike",
    project: "Workotick",
    duration: "10:00",
    in: "10:00 AM",
    out: "10:00 PM",
    department: "Development" 
  }, {
    name: "Mike",
    project: "Workotick",
    duration: "10:00",
    in: "10:00 AM",
    out: "10:00 PM",
    department: "Development" 
  }, {
    name: "Mike",
    project: "Workotick",
    duration: "10:00",
    in: "10:00 AM",
    out: "10:00 PM",
    department: "Development" 
  }, ]
  const [filters, setFilters] = useState({
    department: null,
    name: null,
    project: null
  });

  const handleChange = (value, type) => {
    setFilters((prevFilters) => ({ ...prevFilters, [type]: value }));
  };

  const clearState = (type) => {
    setFilters((prevFilters) => ({ ...prevFilters, [type]: null }));
  };

  const filteredData = useFilter(data, filters);
  const projectOptions = [...new Set(data.map(item => item.project))];
  const departmentOptions = [...new Set(data.map(item => item.department))];
  const memberOptions = [...new Set(data.map(item => item.name))];
  return (
    <MainLayout>
        <div className='p-5 py-2'>
        <div className='flex flex-col '>
          <div className='flex flex-row items-center gap-4'>
            <button className='p-1 px-2 rounded-md border bg-white' onClick={() => navigate('/reports') }><LeftOutlined /></button>
            <h1 className='xl:text-2xl text-2xl lg:text-xl my-4 font-semibold font-poppins'>In / Outs</h1>
          </div>   
          <div className="flex gap-2 ml-2 main flex-col">
            <div className='flex  gap-2 flex-col xl:flex-row justify-end mr-2'>
              <div className='flex gap-2 j'>
                <Select
                  value={filters.name}
                  onChange={(value) => handleChange(value, 'name')}
                  onClear={() => clearState('name')}
                  allowClear='true'
                  placeholder="Member"
                  className='flex-grow w-40'
                  options={memberOptions.map(member => ({ value: member, label: member }))}
                />
                <Select
                    value={filters.department}
                    placeholder='Department'
                    onChange={(value) => handleChange(value, 'department')}
                    onClear={() => clearState('department')}
                    allowClear='true'
                    className='flex-grow w-40'
                    options={departmentOptions.map(department => ({ value: department, label: department }))}
                  />
                 <Select
                  placeholder="Project"
                  value={filters.project}
                  onChange={(value) => handleChange(value, 'project')}
                  onClear={() => clearState('project')}
                  className="flex-grow w-40"
                  allowClear='true'
                  options={projectOptions.map((project) => ({
                    value: project,
                    label: project,
                  }))}
                />
              </div>
              <div  className='flex  h-8 flex-row gap-2 md:justify-end'>
                <div className='flex gap-2 '>         
                  <CustomDatePicker />
                </div>
                <div>
                <Button className='flex gap-2 h-8 items-center bg-white'>
                  <img src={CSV} alt="download-icon" />
                  <p className='text-[#9DA5B5]'>CSV</p>
                </Button>
                </div>
              </div>
            </div>
          
          </div>
          
        </div>
          <InOutsTable data={filteredData}  />    
      </div>
    </MainLayout>
  )
}
