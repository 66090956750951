import { has } from "lodash";
import { FaArrowUp } from "react-icons/fa6";
import { IoLockClosed } from "react-icons/io5";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';

export default function TeamReports() {

  const { activeOrganization } = useSelector((state) => state.auth);
  const { plan } = activeOrganization;
  const { features } = plan;

  const reports = [
    {
      heading: 'Top Performer',
      description: 'Recognize and reward your top achievers.',
      link: 'top-performer',
      locked: !has(features, 'star-performer')
    },
    {
      heading: 'Attendance Insight',
      description: 'Keep tabs on team punctuality and attendance.',
      link: 'attendance',
      locked: !has(features, 'attendance-insight')
    },
    {
      heading: 'Productivity Pulse',
      description: 'Gauge your team\'s efficiency at a glance.',
      link: 'productivity',
      locked: !has(features, 'productivity-pulse')
    },
    {
      heading: 'Target Hour Tracker',
      description: 'Stay on course with your designated work hours.',
      link: 'target-hours',
      locked: !has(features, 'target-hour-tracker')
    },
    {
      heading: 'In & Outs',
      description: 'Gauge your team\'s efficiency at a glance.',
      link: 'in-and-outs',
      locked: false
    },
    {
      heading: 'Weekly Timesheet',
      description: 'Comprehensive summaries for weekly activities.',
      link: 'timesheet/weekly',
      locked: false
    },
    {
      heading: 'Daily Timesheet',
      description: 'Dive deep into working hours by each member.',
      link: 'timesheet/daily',
      locked: !has(features, 'daily-timesheet')
    },
    {
      heading: 'Overtime Analysis',
      description: 'Track and manage overtime hours for better resource allocation.',
      link: 'overtime',
      locked: !has(features, 'overtime-analysis')
    }
  ]

  return (
    <div className='bg-white p-6 rounded-lg'>
      <h1 className='xl:text-lg text-lg lg:text-lg my-4 font-slightly-bold  font-poppins '>
        Team Reports
      </h1>
      <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5'>
        {
          reports.map(report => (
            <Link
              className='col-span-1 font-poppins bg-[#FAFAFA] p-3 rounded-md cursor-pointer'
              to={report.locked ? '#' : report.link}>
              <div className='flex justify-between'>
                <p className='font-semibold text-lg'>{report.heading}</p>
                <div
                  className='p-2 bg-[#3376FF] bg-opacity-10 rounded-full'
                  style={{ transform: `${!report.locked && 'rotate(45deg)'}` }}
                >
                  {
                    report.locked ?
                      <IoLockClosed size={20} /> :
                      <FaArrowUp size={20} />
                  }
                </div>
              </div>
              <div className='mt-4 mb-2 text-[#818181] text-left  '>
                {report.description}
              </div>
            </Link>

          ))
        }
      </div>
    </div>
  )
}
