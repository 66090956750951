import { Button, Table as AntTable, Tag, Tooltip, Progress } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Api from "../../../utils/Axios";

export default function Table({ filters }) {

  const [data, setData] = useState([]);
  const [recordFilters, setRecordFilters] = useState(undefined)
  const [pagination, setPagination] = useState(undefined);
  const { activeOrganization } = useSelector((state) => state.auth)

  const getReport = useCallback(async () => {
    try {
      const { data: { data: records, pagination: paginationData } } = await Api.Get(`organization/${activeOrganization.id}/report/productivity-pulse`, recordFilters)
      setData(records);
      setPagination(paginationData)
    } catch (error) {
      console.error(error);
    }
  }, [activeOrganization, recordFilters])

  const handleTableChange = useCallback((pagination, filters, sorter, extra) => {
    const { field, order } = sorter;
    const orderByMap = {
      ascend: 'asc',
      descend: 'desc'
    };

    const sortByMap = {
      active_time: 'active_hours',
      logged_time: 'logged_hours',
      productivity: 'productivity'
    }

    setRecordFilters((prev) => ({
      ...prev,
      sort_by: sortByMap[field],
      order_by: orderByMap[order]
    }));
  }, []);


  const handleLoadMore = useCallback(async () => {
    const { data: { data: records, pagination: paginationData } } = await Api.Get(pagination?.links?.next, recordFilters)
    setData((prev) => [
      ...prev,
      ...records
    ]);
    setPagination(paginationData)
  }, [pagination, recordFilters])

  useEffect(() => {
    getReport()
  }, [getReport])

  useEffect(() => {
    if (!filters) return

    setRecordFilters(filters);
  }, [filters])

  const columns = [
    {
      title: 'Rank',
      dataIndex: "rank",
      align: "center",
      render: (_, __, index) => <p>{`#${index + 1}`}</p>,
    },
    {
      title: "Name",
      dataIndex: "user",
      align: "center",
      render: (_, record) => (
        <div className='flex items-center gap-2 w-full mx-auto'>
          <div className='border-4 rounded-full border-primary overflow-hidden'>
            <img
              src={record.avatar}
              className='w-10 border-4 border-primary'
              alt='profile'
            />
          </div>
          <div>
            <p className='font-semibold text-left'>{record.name}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Active Hours",
      dataIndex: "active_time",
      align: "center",
      sorter: () => { }
    },
    {
      title: "Logged Hours",
      dataIndex: "logged_time",
      align: "center",
      sorter: () => { }
    },
    {
      title: 'Productivity',
      dataIndex: 'productivity',
      key: 'productivity',
      render: (data) => (
        <Progress percent={parseInt(data)} color={'#3376FF'} />
      ),
      sorter: () => { }
    },
    {
      title: "Department",
      dataIndex: "department_names",
      align: "center",
      render: (departments) => {
        const firstTwoDepartments = departments.slice(0, 2);
        const restDepartments = departments.slice(2);

        return (
          <>
            {firstTwoDepartments.map((department, index) => (
              <Tag color='purple' className='font-poppins mt-1' key={index}>
                {department}
              </Tag>
            ))}
            {restDepartments.length > 0 && (
              <Tooltip title={restDepartments.map(department => department).join(', ')}>
                <Tag color='purple' className='font-poppins mt-1'>
                  +{restDepartments.length}
                </Tag>
              </Tooltip>
            )}
          </>
        )

      }
    },
  ];

  return (
    <div className='overflow-auto bg-white rounded-md p-2 mt-3'>
      <AntTable
        dataSource={data}
        columns={columns}
        onChange={handleTableChange}
        className='m-2'
        pagination={false}
      />
      {
        pagination?.links?.next && (
          <div className="load-more sticky flex justify-center top-0">
            <Button
              onClick={handleLoadMore}
              type="primary"
              className="font-poppins py-2 px-5 h-auto"
            >Load More</Button>
          </div>
        )
      }
    </div>
  );
}
