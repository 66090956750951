import { useCallback, useEffect, useState } from 'react';
import { Button, Select } from "antd";
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { LuDownload } from 'react-icons/lu';
import Api from '../../../utils/Axios';
import CustomDatePicker from '../../../components/CustomDatePicker';

const DEFAULT_FILTERS = {
  departments: false,
  projects: false,
  users: false,
  range_picker: false,
  single_picker: false,
}

export default function Filters({ filterss, onChange, report }) {

  const allowedFilters = { ...DEFAULT_FILTERS, ...filterss }

  const [downloading, setDownloading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const { activeOrganization } = useSelector((state) => state.auth);

  const [filters, setFilters] = useState({
    department: undefined,
    projects: undefined,
    start_date: undefined,
    end_date: undefined,
    date: undefined
  });

  const getMetaInfo = useCallback(async () => {
    try {
      const { data: { users, projects, departments } } = await Api.Get(`organization/${activeOrganization?.id}/timesheet/meta`)
      setProjects(projects.map(item => ({ value: item.id, label: item.name })));
      setDepartments(departments.map(item => ({ value: item.id, label: item.name })));
      setUsers(users.map(item => ({ value: item.id, label: item.name })));
    } catch (error) {
      console.error(error)
    }
  }, [activeOrganization])

  const downloadCSV = useCallback(async () => {
    try {
      setDownloading(true);
      const response = await Api.Get(`organization/${activeOrganization.id}/report/${report}/download`, filters, {
        responseType: 'blob'
      })
      if (response) {
        const blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${report}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {

    } finally {
      setDownloading(false);
    }
  }, [activeOrganization, report, filters])

  useEffect(() => {
    onChange(filters)
    // debounce(() => {
    // }, 500)
    // eslint-disable-next-line
  }, [filters])

  useEffect(() => {
    getMetaInfo()
  }, [getMetaInfo])

  return (
    <div className='flex gap-2 xl:items-center main flex-col flex-1'>
      <div className='flex gap-2 flex-col md:flex-row flex-wrap justify-end w-full'>
        {
          allowedFilters.departments && (
            <Select
              placeholder='Department'
              allowClear='true'
              className='flex-1'
              mode='multiple'
              maxTagCount={1}
              options={departments}
              onChange={(value) => setFilters(prev => ({
                ...prev,
                departments: value
              }))}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          )
        }
        {
          allowedFilters.projects && (
            <Select
              placeholder='Project'
              className='flex-1'
              allowClear='true'
              mode='multiple'
              maxTagCount={1}
              options={projects}
              onChange={(value) => setFilters(prev => ({
                ...prev,
                projects: value
              }))}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          )
        }
        {
          allowedFilters.users && (
            <Select
              placeholder='Users'
              className='flex-1'
              allowClear='true'
              mode='multiple'
              maxTagCount={1}
              options={users}
              onChange={(value) => setFilters(prev => ({
                ...prev,
                team_members: value
              }))}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              disabled={activeOrganization?.role === 'user'}
            />
          )
        }
        {
          allowedFilters.range_picker && (
            <CustomDatePicker.RangePicker
              onChange={(value) => setFilters(prev => ({
                ...prev,
                start_date: dayjs(value[0]).format('YYYY-MM-DD'),
                end_date: dayjs(value[1]).format('YYYY-MM-DD')
              }))}
            />
          )
        }
        {
          allowedFilters.single_picker && (
            <CustomDatePicker
              onChange={(value) => setFilters(prev => ({
                ...prev,
                date: dayjs(value).format('YYYY-MM-DD')
              }))}
            />
          )
        }
        <Button className='gap-2 items-center flex bg-white h-auto' onClick={downloadCSV} loading={downloading}>
          <LuDownload size={20} className='text-primary' />
          <span className='text-[#9DA5B5]'>CSV</span>
        </Button>
      </div>
    </div>
  )
}
