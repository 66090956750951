import BgLogo from "../../assets/bg-logo.png";
import { Button, message as Notify } from "antd";
import { useCallback, useMemo, useState } from "react";
import Api from "../../utils/Axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../../features/auth/authActions";
import clsx from "clsx";


export default function Pakages({ plan, onError }) {

  const [loading, setLoading] = useState(false);
  const { activeOrganization, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const activePlan = useMemo(() => {
    return activeOrganization?.plan?.id === plan.id
  }, [activeOrganization, plan])

  const resolveRoute = useCallback(() => {

    let mode = ''

    if (!activeOrganization?.plan) mode = 'subscribe';
    if (activePlan) mode = 'cancel';
    if (activeOrganization?.plan?.price > plan.price || activeOrganization?.plan?.price < plan.price) mode = 'switch'

    const routes = {
      'subscribe': `organization/${activeOrganization.id}/subscription/subscribe/${plan.id}`,
      'switch': `organization/${activeOrganization.id}/subscription/switch/${plan.id}`,
    };

    return routes[mode] || '';

  }, [activeOrganization, plan, activePlan]);

  const handleSubscribe = useCallback(async () => {
    try {
      setLoading(true)
      const route = resolveRoute();

      if (!route || route === '') return false;

      const { message } = await Api.Get(route);
      window.dataLayer.push({
        'event': 'plan_subscribed',
        'organization': activeOrganization.name,
        'plan': plan.name,
        'user_email': user.email
      })
      await dispatch(getUserDetails());
      Notify.success(message);

      navigate('/');
    } catch (error) {
      if (error.response && error.response.status === 422) {
        onError({ errors: error.response.data.errors.switch, plan });
      }
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [navigate, dispatch, resolveRoute, onError, plan, activeOrganization, user])

  return (
    <>
      <div
        className={`col-span-2 lg:col-span-1 rounded-lg relative shadow-md flex flex-col ${!activePlan && 'bg-[#F3F7FF]'}`}
        key={plan.id}
        style={{
          ...(activePlan && {
            background: 'linear-gradient(344.44deg, #2257C4 23.66%, #4784FF 100%)',
            color: '#fff'
          })
        }}
      >
        <img
          src={BgLogo}
          alt='background-logo'
          className='absolute'
        />
        <div className='m-6 flex justify-between flex-col flex-grow font-poppins'>
          <div>
            <h1 className='font-bold text-4xl mt-4'>{plan.name}</h1>
            <p
              className={`${!activePlan ? 'text-light' : 'text-white'} my-1 text-left`}>
              {plan.description}
            </p>
            <h1
              className={`text-5xl font-extrabold mt-4 mb-2 ${!activePlan ? 'text-primary' : 'text-white'}`}>
              {plan.price <= 0 ? 'Free' : `Rs ${plan.price}`}
            </h1>
            <h2 className='font-semibold text-sm'>{plan.price <= 0 ? 'Single User' : 'Per user / per month'}</h2>
            <ul className={clsx({
              'plan-feature-list my-4 border-b-2 border-solid': true,
              'border-gray-100': activePlan,
              'border-primary': !activePlan,
            })}>
              {plan.feature_list?.features.map((feature, index) => (
                <li key={index} className='flex items-center gap-2 my-3 font-poppins'>
                  <p className='text-sm font-semibold font-poppins'>{feature}</p>
                </li>
              ))}
            </ul>
            <ul className='plan-feature-list my-4'>
              {plan.feature_list?.reports.map((feature, index) => (
                <li key={index} className='flex items-center gap-2 my-3'>
                  <p className='text-sm font-semibold font-poppins'>{feature}</p>
                </li>
              ))}
            </ul>
          </div>
          <Button
            loading={loading}
            onClick={handleSubscribe}
            className={`border font-semibold font-poppins border-gray-400 text-base h-12 rounded-md transition-all duration-300 ease-in-out hover:bg-gray-200 bg-white focus:outline-none focus:ring focus:border-blue-300`}
            disabled={activePlan}
          >
            {plan.button_text}
          </Button>
        </div>
      </div>
    </>
  );
}
