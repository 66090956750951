import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Auth from "../components/Middleware/Auth";
import Pakages from "../components/Payment/Pakages";
import Api from "../utils/Axios";
import DowngradeErrorModal from "../components/Modals/DowngradeErrorModal";
import LazyLoad from "../components/LazyLoad";
import PlanPageLoader from "../components/Skeletons/PlanPageLoader";
import AdminView from "../components/Middleware/AdminView";
import SecondaryLayout from "../layouts/SecondaryLayout";

const Plans = () => {

  const { activeOrganization } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [errors, setErrors] = useState(undefined);
  const [selectedPlan, setSelectedPlan] = useState(undefined);

  const pageHeading = useMemo(() => {
    return !activeOrganization?.plan ? 'Select Your Plan and Start Your Free Trial' : 'Manage your plan'
  }, [activeOrganization])

  const getPlans = useCallback(async () => {

    if (!activeOrganization) return

    try {
      setLoading(true);
      const { data } = await Api.Get(`/organization/${activeOrganization.id}/plans`)
      setPlans(data);
    } catch (errors) {
      console.error(errors)
    } finally {
      setLoading(false);
    }
  }, [activeOrganization]);

  useEffect(() => {
    getPlans()
  }, [getPlans])

  return (
    <Auth>
      <AdminView>
        <SecondaryLayout>
          <div className='bg-backgroundLight min-h-screen flex flex-col items-center justify-center md:p-14 p-4 '>
            <div className="my-6 text-center">
              <h1 className='text-3xl md:text-4xl font-bold text-dark'>
                {pageHeading}
              </h1>
              {
                !activeOrganization?.plan && (
                  <p className='lg:w-7/12 mx-auto my-3 text-base'>
                    Congratulations on starting your new organization! It's time to choose
                    the plan that best fits your team's needs.
                  </p>
                )
              }
            </div>
            <LazyLoad loading={loading} loader={<PlanPageLoader />}>
              <div className='bg-white grid grid-cols-3 gap-4 p-4 lg:p-9 w-full rounded'>
                {
                  plans.map(item => <Pakages plan={item} onError={({ errors, plan }) => {
                    setErrors(errors);
                    setSelectedPlan(plan)
                  }} />)
                }
              </div>
            </LazyLoad>
            <DowngradeErrorModal
              open={!!errors}
              errors={errors}
              plan={selectedPlan}
              onClose={() => {
                setErrors(undefined);
                setSelectedPlan(undefined);
              }}
            />
          </div>
        </SecondaryLayout>
      </AdminView>
    </Auth>
  );
};

export default memo(Plans);
