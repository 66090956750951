import { Button, Modal, message as Notify } from "antd";
import React, { useCallback, useState } from "react";
import sad from "../../assets/sad.png";
import { useSelector } from "react-redux";
import Api from "../../utils/Axios";

export default function DeleteModal({ open, onClose, entity, mode }) {
  const { activeOrganization } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);
      let url = `organization/${activeOrganization?.id}/team/${mode === "members" ? "member" : "invitation"
        }/${entity.id}`;
      const { message } = await Api.Delete(url);
      Notify.success(message);
      onClose(true);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        Notify.error(error.response.data.message);
      } else {
        console.error(error);
        Notify.error('Something went wrong');
      }
    } finally {
      setLoading(false);
    }
  }, [entity, activeOrganization?.id, mode, onClose]);

  return (
    <div>
      <Modal open={open} onCancel={onClose} footer={false}>
        <div className="md:m-10 flex flex-col items-center text-center font-poppins">
          <img src={sad} alt="sad-face" className="mx-auto" />
          <p className="font-slightly-bold text-2xl my-2">
            Are you sure you want to delete{" "}
            {(mode === "members" && entity) && (
              <>
                {entity.name} from <b>{activeOrganization?.name}</b>
              </>
            )}
            ?
          </p>
          {mode === "members" && (
            <p className="text-smtext font-slightly-bold">
              Deleting the account will result in deletion of the screenshots of
              the user as well
            </p>
          )}
          <div className="flex gap-2">
            <Button
              type="primary"
              className="bg-primary  h-9 mt-5 rounded-sm w-40  font-poppins "
              onClick={handleDelete}
              loading={loading}
            >
              Confirm Delete
            </Button>
            <Button
              type="primary"
              className="bg-primary w-40 h-9 mt-5 rounded-sm  font-poppins"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
