import { Button, Modal, Typography } from 'antd';
import logoutSvg from '../assets/logout.svg';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from "../features/auth/authActions";
import { useNavigate } from 'react-router-dom';

export default function LogoutModal({ show, onClose }) {

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const handleLogout = useCallback(async () => {
		try {
			setLoading(true)
			await dispatch(logout())
			navigate('/login');
		} catch (errors) {
			console.error(errors);
		} finally {
			setLoading(false)
		}
	}, [dispatch, navigate])

	return (
		<Modal
			open={show}
			onCancel={onClose}
			footer={false}
			centered
		>
			<div className="logout-wrapper mt-5 max-w-xs mx-auto">
				<div className="text-center">
					<img src={logoutSvg} alt="logout" className='mx-auto mt-5' width={40} height={40} />
					<Typography.Title level={3} className='font-poppins text-dark font-medium mt-3'>
						Logout
					</Typography.Title>
					<Typography.Paragraph className='font-poppins text-dark font-medium mt-3 text-base'>
						Are you sure you want to logout ?
					</Typography.Paragraph>
					<div className="grid grid-cols-2 gap-x-5">
						<Button className='rounded-sm col-span-1 font-poppins font-medium h-10' onClick={onClose}>
							Cancel
						</Button>
						<Button type='primary' className='rounded-sm col-span-1 bg-primary font-poppins font-medium h-10' onClick={handleLogout} loading={loading}>
							Confirm
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}
