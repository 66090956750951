import React from "react";
import { Route, Routes } from "react-router-dom";
import ActiveTime from "./components/Reports/ActiveTime";
import InOuts from "./components/Reports/InOuts";
import ProjectPerformance from "./components/Reports/ProjectPerformance";
import RoleGuard from './components/RoleGuard';
import CreateOrganization from "./views/Auth/CreateOrganization";
import ForgotPassword from "./views/Auth/ForgotPassword";
import Invitation from "./views/Auth/Invitation";
import Login from "./views/Auth/Login";
import NewPassword from "./views/Auth/NewPassword";
import Organization from "./views/Auth/Organization";
import Register from "./views/Auth/Register";
import Settings from "./views/Auth/Settings/Settings";
import Verify from "./views/Auth/Verify";
import Department from "./views/Dashboard/Department";
import Main from "./views/Dashboard/Main";
import Meetings from "./views/Dashboard/Meetings";
import Profile from "./views/Dashboard/Profile";
import Screenshots from "./views/Dashboard/Screenshots";
import Team from "./views/Team/Team";
import UpdatePassword from "./views/Dashboard/UpdatePassword";
import GetStarted from "./views/GetStarted";
import Plans from "./views/Plans";
import Projects from "./views/Projects/Project";
import AttendanceInsightReport from "./views/Reports/AttendanceReport/AttendanceInsightReport";
import ProductivityReport from "./views/Reports/ProductivityReport/ProductivityReport";
import Reports from "./views/Reports/Reports";
import TopPerformerReport from "./views/Reports/TopPerformerReport/TopPerformerReport";
import Timesheet from "./views/Timesheet/Timesheet";
import TargetHoursReport from "./views/Reports/TargetHoursReport/TargetHoursReport";
import DailyTimesheetReport from "./views/Reports/TimesheetReport/DailyTimesheetReport";
import OvertimeReport from "./views/Reports/OvertimeReport/OvertimeReport";
import ProjectMemberReport from "./views/Reports/ProjectReport/MemberReport";
import ProjectDepartmentReport from "./views/Reports/ProjectReport/DepartmentReport";
import InOutsReport from "./views/Reports/In&Outs/InOutsReport";

export default function Routing() {
  return (
    <Routes>
      <Route path="/invite" element={<Invitation />} />
      <Route path="/" element={<Main />} />
      <Route path="/screenshots" element={<Screenshots />} />
      <Route path="/team" element={<RoleGuard Component={Team} />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/login" element={<Login />} />
      <Route path="/plans" element={<Plans />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/organization" element={<Organization />} />
      <Route path="/register" element={<Register />} />
      <Route path="/create-organization" element={<CreateOrganization />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/verify" element={<Verify />} />
      <Route path="/reset-password" element={<NewPassword />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/update-password" element={<UpdatePassword />} />
      <Route path="/department" element={<Department />} />
      <Route path="/meetings" element={<Meetings />} />
      <Route path="/settings" element={<RoleGuard Component={Settings} />} />
      <Route path="/get-started" element={<GetStarted />} />

      {/* Reporting */}
      <Route path="/reports" element={<Reports />} />
      <Route path="/reports/top-performer" element={<TopPerformerReport />} />
      <Route path="/reports/attendance" element={<AttendanceInsightReport />} />
      <Route path="/reports/productivity" element={<ProductivityReport />} />
      <Route path="/reports/target-hours" element={<TargetHoursReport />} />
      <Route path="/reports/timesheet/weekly" element={<Timesheet />} />
      <Route path="/reports/timesheet/daily" element={<DailyTimesheetReport />} />
      <Route path="/reports/overtime" element={<OvertimeReport />} />
      <Route path="/reports/project/member" element={<ProjectMemberReport />} />
      <Route path="/reports/project/department" element={<ProjectDepartmentReport />} />
      <Route path="/reports/in-and-outs" element={<InOutsReport />} />
      <Route path="in-outs" element={<InOuts />} />
      <Route path="active" element={<ActiveTime />} />
      <Route path="project-performance" element={<ProjectPerformance />} />



    </Routes>
  );
}
