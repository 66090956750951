import React, { useState } from 'react';
import MainLayout from '../../../layouts/MainLayout';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import Table from './Table';
import Filters from '../components/Filters';
import Authorized from '../../../components/Middleware/Authorized';

const ENABLED_FILTERS = {
  departments: true,
  projects: true,
  users: true,
  range_picker: true
}

export default function ProductivityReport() {

  const navigate = useNavigate();
  const [filters, setFilters] = useState(undefined)

  return (
    <MainLayout>
      <Authorized permission='productivity-pulse'>
        <div className='md:p-5 py-2'>
          <div className='flex items-center gap-x-6'>
            <div className='flex items-center gap-4 mt-4 mb-7'>
              <Button className="w-10 h-10 flex justify-center items-center p-2" onClick={() => navigate(-1)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                </svg>
              </Button>
              <h1 className='xl:text-2xl text-2xl lg:text-xl my-4 font-semibold font-poppins'>
                Time Summary
              </h1>
            </div>
            <div className='flex justify-end items-center flex-1 gap-2'>
              <div className="flex gap-2 xl:items-center main flex-col flex-1">
                <div className="flex gap-2 flex-col md:flex-row flex-wrap justify-end w-full">
                  <Filters
                    filterss={ENABLED_FILTERS}
                    onChange={(newFilters) => setFilters(newFilters)}
                    report='productivity-pulse'
                  />
                </div>
              </div>
            </div>
          </div>
          <Table filters={filters} />
        </div>
      </Authorized>
    </MainLayout>
  )
}
