import { createSlice } from "@reduxjs/toolkit";
import { getUserDetails, logout } from "./authActions";

const storedActiveOrg = localStorage.getItem('activeOrg');
const storedUser = localStorage.getItem('user');

const initialState = {
  isAuthenticated: !!storedUser,
  user: JSON.parse(storedUser) || {},
  token: localStorage.getItem("token") || null,
  activeOrganization: storedActiveOrg ? JSON.parse(storedActiveOrg) : undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    destroySession: (state) => {
      state.isAuthenticated = false;
      state.activeOrganization = undefined;
      state.user = undefined;
      state.token = undefined;
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("activeOrg");
    },
    setAuthenticated: (state, { payload }) => {
      state.isAuthenticated = true;
      state.user = payload.user;
      state.token = payload.token;
      localStorage.setItem("token", payload.token);
      localStorage.setItem("user", JSON.stringify(payload.user ?? {}));
    },
    updateUser: (state, { payload }) => {
      const activeOrg = payload.organizations.find(organization => organization.id === state?.activeOrganization?.id);
      if (activeOrg) {
        state.activeOrganization = activeOrg;
        localStorage.setItem('activeOrg', JSON.stringify(activeOrg));
      }
      localStorage.setItem("user", JSON.stringify(payload));
    },
    setActiveOrganization: (state, { payload }) => {
      state.activeOrganization = payload;
      localStorage.setItem('activeOrg', JSON.stringify(payload))

    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state, action) => {
      state.isAuthenticated = false;
      state.activeOrganization = undefined;
      state.user = undefined;
      state.token = undefined;
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("activeOrg");
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      authSlice.caseReducers.updateUser(state, { payload: action.payload.data });
    })
  },
});

export const {
  setAuthenticated,
  destroySession,
  updateUser,
  setActiveOrganization,
} = authSlice.actions;

export default authSlice.reducer;
