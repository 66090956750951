import { Button, Modal, message as Notify } from "antd";
import React, { useCallback, useState } from "react";
import sad from "../../assets/sad.png";
import { useSelector } from "react-redux";
import Api from "../../utils/Axios";

export default function SuspendModal({ open, onClose, entity, suspended }) {
  const { activeOrganization } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  const handleConfirmation = useCallback(async () => {
    try {
      setLoading(true);
      let url = `organization/${activeOrganization?.id}/team/member/${entity?.id}/${suspended ? 'resume' : 'suspend'}`;
      const { message } = await Api.Post(url);
      Notify.success(message);
      onClose(true);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        Notify.error(error.response.data.message);
      } else {
        console.error(error);
        Notify.error('Something went wrong');
      }
    } finally {
      setLoading(false);
    }
  }, [entity, activeOrganization?.id, onClose, suspended]);

  return (
    <Modal open={open} onCancel={onClose} footer={false}>
      <div className="md:m-10 flex flex-col items-center text-center font-poppins">
        <img src={sad} alt="sad-face" className="mx-auto" />
        <p className="font-slightly-bold text-2xl my-2">
          Are you sure you want to {suspended ? 'resume' : 'suspend'} {entity?.name}?
        </p>
        {

          <p className="text-smtext font-slightly-bold">
            {
              !suspended ?
                "A suspended user will lose his right to login to the organization and track time. The data of user will remain intact and you can resume the access at any point."
                : "Revoking a suspension restores the user's login and tracking rights while maintaining their data."
            }
          </p>
        }
        <div className="flex gap-2">
          <Button
            type="primary"
            className="bg-primary  h-9 mt-5 rounded-sm font-poppins"
            onClick={handleConfirmation}
            loading={loading}
          >
            Confirm {suspended ? 'Resume' : 'Suspension'}
          </Button>
          <Button
            type="primary"
            className="bg-primary w-40 h-9 mt-5 rounded-sm  font-poppins"
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}
