import { Avatar, Button, Table, Tooltip, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import MeetingModal from "./MeetingModal";
import MeetingsDeleteModal from "./MeetingsDeleteModal";
import { FileTextOutlined } from "@ant-design/icons";
import AddNotesModal from "./AddNotesModal";
import { useSelector } from "react-redux";
import Api from "../../utils/Axios";
import dayjs from "dayjs";

export default function MeetingsTable({ shouldRefresh, refreshed, isArchived }) {
  const { activeOrganization, user } = useSelector((state) => state.auth);
  const [meetings, setMeetings] = useState([]);
  const [tableLoading, setTableLoading] = useState(false)
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(undefined);

  const handleMeetingEdit = useCallback((record) => {
    setSelectedMeeting(record);
    setIsModalOpen(true);
  }, []);

  const handleMeetingArchive = useCallback((record) => {
    setSelectedMeeting(undefined)
    setSelectedMeeting(record)
    setShowArchiveModal(true);
  }, []);

  const handleArchiveModalClose = useCallback(() => {
    setShowArchiveModal(false);
    refreshed()
  }, [refreshed])

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      align: "center",
      render: (text) => (
        <div className='flex gap-2 items-center justify-center'>
          <p className='text-center'>{text}</p>
        </div>
      ),
    },
    {
      title: "Attendees",
      dataIndex: "attendees",
      align: "center",
      render: (members) => (
        <div className='flex items-center justify-center'>
          {members ? (
            <div className='avatar'>
              <Avatar.Group
                maxCount={2}
                maxPopoverTrigger='hover'
                size='default'
                maxStyle={{
                  color: "#f56a00",
                  backgroundColor: "#fde3cf",
                  cursor: "pointer",
                }}>
                {members.map((item, index) => (
                  <Tooltip
                    title={item.name}
                    placement='top'>
                    <Avatar
                      key={index}
                      src={item?.avatar}
                    />
                  </Tooltip>
                ))}
              </Avatar.Group>
            </div>
          ) : (
            <p className='ml-6 text-center'>---</p>
          )}
        </div>
      ),
    },
    {
      title: "When",
      dataIndex: "start_time",
      align: "center",
      width: 200,
      render: (value) => (
        <div className='text-center w-24 lg:w-full'>
          <p>{dayjs(value).format('DD MMM')}</p>
          <p>{dayjs(value).format('hh:mm A')}</p>
        </div>
      ),
    },
    {
      title: "Meeting Notes",
      align: "center",
      dataIndex: "notes",
      render: (data, record) => (
        <>
          <Typography.Paragraph
            ellipsis={{
              rows: 2
            }}
            className="mb-0"
          >
            {data ?? '---'}
          </Typography.Paragraph>
          {
            (record.notes && record.notes.length > 50) && (
              <Button type="link" onClick={() => {
                setSelectedMeeting(record);
                setShowNotesModal(true)
              }}>
                View More
              </Button>
            )
          }
        </>
      ),
    },
    !isArchived ? {
      title: "Location",
      align: "center",
      key: "link",
      render: (value, record) => {
        return record.type === 'online' ? (
          <a href={value.link} target="_blank" rel="noopener noreferrer" className="w-24 text-white rounded-md py-1 bg-primary block">
            Join
          </a>
        ) : value.location;
      }
    } : {},
    !isArchived ?
      {
        title: "Action",
        key: "action",
        align: "center",
        render: (_, record) => (
          <div className='flex gap-3 justify-center'>
            <Tooltip title='Meeting notes'>
              <Button className="bg-primary text-white p-0 h-10 w-8 flex items-center justify-center disabled:bg-gray-200"
                onClick={() => {
                  setSelectedMeeting(record);
                  setShowNotesModal(true);
                }}>
                <FileTextOutlined className="text-xl" />
              </Button>
            </Tooltip>

            {
              (user.id === record.host_id || activeOrganization.role === 'admin' || (activeOrganization.role === 'department_head' && record.can_edit)) && (
                <>
                  <Button
                    className='w-24 text-white rounded-md py-1 bg-[#73C255] border-none'
                    onClick={() => handleMeetingEdit(record)}>
                    Edit
                  </Button>
                  <Button
                    className={`w-24 text-white rounded-md py-1 border-none ${!isArchived ? 'bg-[#EE3636]' : 'bg-green-600'}`}
                    onClick={() => handleMeetingArchive(record)}>
                    {!isArchived ? 'Archive' : 'Restore'}
                  </Button>
                </>
              )
            }
          </div>
        ),
      } : {},
  ];

  const getMeetings = useCallback(async () => {
    try {
      setTableLoading(true);
      let params = {};
      if (isArchived) {
        params.archived = true;
      }
      const { data } = await Api.Get(`/organization/${activeOrganization?.id}/meeting`, params);
      setMeetings(data);
      if (shouldRefresh) refreshed()
    } catch (error) {
      console.error(error);
    } finally {
      setTableLoading(false)
    }
  }, [activeOrganization, refreshed, shouldRefresh, isArchived]);

  const handleModalClose = useCallback((refresh) => {
    setIsModalOpen(false);
    setSelectedMeeting(undefined)
    if (refresh) {
      getMeetings()
    }
  }, [getMeetings,]);

  const handleNoteModalClose = useCallback((refresh) => {
    setShowNotesModal(false);
    setSelectedMeeting(undefined)
    if (refresh) {
      getMeetings()
    }
  }, [getMeetings])

  useEffect(() => {
    getMeetings();
  }, [getMeetings])

  useEffect(() => {
    if (shouldRefresh) getMeetings()
  }, [shouldRefresh, getMeetings])

  return (
    <div className='overflow-auto mt-1 p-2 bg-white rounded-md'>
      <Table
        dataSource={meetings}
        columns={columns}
        pagination={true}
        className='m-2'
        loading={tableLoading}
      />
      <MeetingsDeleteModal
        onClose={handleArchiveModalClose}
        open={showArchiveModal}
        meeting={selectedMeeting}
        isArchived={isArchived}
      />
      <MeetingModal
        onClose={handleModalClose}
        open={isModalOpen}
        meeting={selectedMeeting}
      />
      <AddNotesModal
        open={showNotesModal}
        meeting={selectedMeeting}
        onClose={handleNoteModalClose}
      />
    </div>
  );
}
