import { Button, Modal, message } from 'antd'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux';
import Api from '../../utils/Axios';
import { useNavigate } from 'react-router-dom';

export default function DeleteOrganizationModal({ open, onClose }) {

  const navigate = useNavigate();
  const { activeOrganization } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);
      await Api.Delete(`organization/${activeOrganization.id}`);
      message.success('Organization deleted successfully');
      onClose();
      navigate('/organization');
    } catch (error) {
      console.error(error)
      message.error('Something went wrong');
    } finally {
      setLoading(false);
    }
  }, [activeOrganization, onClose, navigate]);

  return (
    <Modal open={open} onCancel={onClose} footer={false} centered>
      <div className="md:m-10 flex flex-col items-center font-poppins">
        <p className="font-slightly-bold text-2xl my-2 text-center">
          Are you sure you want to delete your organization?
        </p>
        <p className="text-sm font-slightly-bold mt-5">
          This action is irreversible. Deleting organization will remove all of screenshots, meeting, department etc.
        </p>
        <div className="flex gap-2">
          <Button
            type="primary"
            className="bg-primary w-40 h-9 mt-5 rounded-sm  font-poppins"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="bg-primary  h-9 mt-5 rounded-sm w-40 font-poppins"
            onClick={handleDelete}
            loading={loading}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  )
}
