import { useMemo } from "react";

const useFilter = (initialData, filters) => {

  const filteredData = useMemo(() => {
    let results = initialData;

    Object.entries(filters).forEach(([filterType, filterValue]) => {
      if (filterValue) {
        results = results.filter(
          (data) => data[filterType].toLowerCase() === filterValue.toLowerCase()
        );
      }
    });

    return results;
  }, [filters, initialData]);

  return filteredData;
};

export default useFilter;
