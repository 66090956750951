import React, { useCallback, useState } from "react";
import MainLayout from "../../layouts/MainLayout";
import { Button } from "antd";
import MeetingModal from "../../components/Meetings/MeetingModal";
import MeetingsTable from "../../components/Meetings/MeetingsTable";
import { useNavigate } from "react-router-dom";

export default function Meetings() {
  const [archive, setArchive] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [showMeetingModal, setShowMeetingModal] = useState(false);
  const navigate = useNavigate();

  const handleCancel = useCallback((refresh) => {
    setShowMeetingModal(false);
    if (refresh) {
      setRefreshTable(refreshTable)
    }
  }, [refreshTable]);

  return (
    <MainLayout>
      <div className='py-8 md:p-5 font-poppins'>
        <div className='flex flex-col md:flex-row justify-between items-center'>
          <div className="flex items-center gap-x-6 mt-4 mb-7">
            <Button className="w-10 h-10 flex justify-center items-center p-2" onClick={() => navigate(-1)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
              </svg>
            </Button>
            <h1 className="text-2xl font-semibold">Meetings</h1>
          </div>
          <div className='flex gap-3 my-2'>
            <Button
              onClick={() => setArchive((prevArchive) => !prevArchive)}
              className='font-poppins rounded-md h-10 bg-white'>
              {!archive ? "Archived Meetings" : "Active Meetings"}
            </Button>
            <Button
              type='primary'
              onClick={() => setShowMeetingModal(true)}
              className='bg-primary flex items-center gap-2 font-poppins h-10 rounded-md'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-presentation"><path d="M2 3h20" /><path d="M21 3v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V3" /><path d="m7 21 5-5 5 5" /></svg>
              <p>Create Meeting</p>
            </Button>
          </div>
        </div>
        <MeetingsTable
          isArchived={archive}
          shouldRefresh={refreshTable}
          refreshed={() => setRefreshTable(false)}
        />
      </div>
      <MeetingModal
        onClose={handleCancel}
        open={showMeetingModal}
      />
    </MainLayout>
  );
}
