import axios from 'axios';
import { useCallback, useMemo } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Placeholder from '../assets/placeholder.webp';
import Restricted from '../assets/Restricted.png';
import NotFound from '../assets/NotFound.png';

export default function RestrictedImage(props) {

  const [imageBlob, setImageBlob] = useState(null);
  const [errorImage, setErrorImage] = useState(null)
  const { activeOrganization } = useSelector((state) => state.auth);
  const token = localStorage.getItem('token');

  const getRestrictedContent = useCallback(async () => {
    try {

      if (!props.src) return false;

      setImageBlob(null)
      const response = await axios.get(props.src, {
        responseType: 'blob',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          'X-ORG': activeOrganization.id
        }
      });
      setImageBlob(response.data)

    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          setErrorImage(Restricted)
        } else if (error.response.status === 404 || error.response.status === 500) {
          setErrorImage(NotFound)
        }
      } else {
        console.error(error)
        setImageBlob(null);
      }
    }
  }, [props.src, token, activeOrganization.id]);

  const imageSrc = useMemo(() => {
    if (imageBlob) {
      return URL.createObjectURL(imageBlob);
    } else if (errorImage) {
      return errorImage;
    } else {
      return Placeholder;
    }
  }, [imageBlob, errorImage]);

  useEffect(() => {
    getRestrictedContent()
  }, [getRestrictedContent]);

  return (
    <img
      {...props}
      alt='restricted-img'
      src={imageSrc}
      onError={(e) => {
        e.target.src = Placeholder;
      }}
    />
  )
}
