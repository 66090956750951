import { message } from 'antd';
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { has } from 'lodash';

export default function Authorized({ children, permission }) {

  const { activeOrganization } = useSelector((state) => state.auth);
  const { plan: { features } } = activeOrganization;
  const navigate = useNavigate();

  const hasPermission = useMemo(() => {
    return has(features, permission) || permission === true;
  }, [features, permission]);

  useEffect(() => {
    if (!hasPermission) {
      message.error('Access denied');
      navigate('/');
    }
  }, [hasPermission, navigate]);

  return (
    <>
      {hasPermission && children}
    </>
  )
}
