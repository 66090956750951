import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Checkbox,
  Form,
  Input,
  Button,
  Typography,
  message as Notify,
} from "antd";
import { useDispatch } from "react-redux";
import { setAuthenticated } from "../../features/auth/authSlice";
import Api from "../../utils/Axios";
import Guest from "../../components/Middleware/Guest";
import AuthLayout from "../../layouts/AuthLayout";

export default function Login() {

  const [emailDisabled, setEmailDisabled] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [searchParams] = useSearchParams();
  const [loginForm] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const redirectedFromInvite = useMemo(() => {
    return searchParams.has('token') && searchParams.get('token') !== ''
  },[searchParams])

  const handleFormSubmit = useCallback(
    async (values) => {
      try {
        setLoading(true);
        const { data, message } = await Api.Post("/login", values);
        dispatch(setAuthenticated(data));
        if(redirectedFromInvite) {
          navigate(searchParams.get('redirectTo'))
        } else {
          navigate("/organization");
        }
        Notify.success(message);
      } catch (error) {
        if (error.response) {
          if (error.response.status === 422) {
            const errors = error.response.data.errors;
            const validationErrors = Object.keys(errors).map((key) => ({
              name: key,
              errors: errors[key],
            }));
            loginForm.setFields(validationErrors);
          } else if (error.response.status === 404) {
            loginForm.setFields([{
              name: 'email',
              errors: [error.response.data.message]
            }]);
          }
        } else {
          console.error(error);
          Notify.error("Something went wrong");
        }
      } finally {
        setLoading(false);
      }
    },
    [dispatch, navigate, loginForm, redirectedFromInvite, searchParams]
  );

  const autoFill = useCallback(() => {
    if (searchParams.has('email') && searchParams.get('email') !== '') {
      loginForm.setFieldValue('email', searchParams.get('email'))
    }

    if (searchParams.has('token') && searchParams.get('token') !== '') {
      setEmailDisabled(true);
    }

  }, [searchParams, loginForm])

  useEffect(() => {
    autoFill()
  }, [autoFill])

  return (
    <Guest>
      <AuthLayout>
        <div className="mt-10 mb-5">
          <Typography.Title
            level={2}
            className="text-left text-dark font-semibold font-poppins"
          >
            Login to your <span className="text-secondary">Workotick</span>{" "}
            account
          </Typography.Title>
          <Typography.Paragraph className="font-poppins text-left font-light">
            Enter Your Gateway to Innovation — Your Productivity Portal Awaits.
          </Typography.Paragraph>
        </div>
        <Form
          layout="vertical"
          className="mt-0 "
          onFinish={handleFormSubmit}
          loading={loading}
          form={loginForm}
        >
          <div className="">
            <Typography.Paragraph className="font-poppins text-dark text-sm mb-1.5">
              Email*
            </Typography.Paragraph>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your work email",
                  type: "email",
                },
              ]}
            >
              <Input
                className="w-full px-4 py-3 border rounded-md"
                placeholder="Enter your work email"
                autoFocus={false}
                disabled={emailDisabled}
                autoComplete="off"
              />
            </Form.Item>
          </div>
          <div className="">
            <Typography.Paragraph className="font-poppins text-dark text-sm mb-1.5">
              Password*
            </Typography.Paragraph>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "Please input your password" }]}
            >
              <Input.Password
                placeholder="Enter password"
                className="w-full px-4 py-3 border rounded-md"
                visibilityToggle={{
                  visible: passwordVisible,
                  onVisibleChange: setPasswordVisible,
                }}
              />
            </Form.Item>
          </div>
          <div className="flex justify-between items-center mb-3">
            <Form.Item name="should_remember" className="mb-0">
              <div className="flex items-center">
                <Checkbox
                  className="mb-0"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <Typography.Paragraph className="mb-0 font-poppins font-normal inline-block ml-1 mt-1">
                  Remember Me
                </Typography.Paragraph>
              </div>
            </Form.Item>
            <Link
              to="/forgot-password"
              className="font-medium font-poppins text-primary"
            >
              Forgot Password?
            </Link>
          </div>
          <Form.Item className="mt-8">
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="w-full h-12 bg-primary text-white font-poppins text-medium"
            >
              Login
            </Button>
          </Form.Item>
        </Form>
        <Typography.Paragraph className="text-center font-poppins font-medium mt-3">
          Not yet registered?{" "}
          <Link to="/register" className="text-secondary underline text-sm mt-1">
            Join Workotick for free
          </Link>
        </Typography.Paragraph>
      </AuthLayout>
    </Guest>
  );
}
