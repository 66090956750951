import React, { useCallback, useEffect, useState } from "react";
import { Button, Table, Tag, Tooltip, message as Notify } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import DeleteModal from "../../components/Modals/DeleteModal";
import TeamMember from "../../components/TeamManagement/TeamMember";
import { useSelector } from "react-redux";
import Api from '../../utils/Axios';
import InviteModal from "../../components/Modals/InviteModal";

export default function InvitationTable({ query, onCountChange }) {

  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const { activeOrganization } = useSelector((state) => state.auth);

  const getInvitations = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await Api.Get(
        `organization/${activeOrganization?.id}/team/invitation`,
        {
          search: query,
        }
      );

      setRecords(data);
    } catch (error) { }
    finally {
      setLoading(false);
    }
  }, [activeOrganization, query]);

  const handleDeleteModal = useCallback((record) => {
    setSelectedMember(record);
    setShowDeleteModal(true);
  }, []);


  const handleDeleteClose = (shouldRefresh) => {
    setSelectedMember(undefined);
    setShowDeleteModal(false);
    if (shouldRefresh) {
      getInvitations()
    }
  };

  const roleSorter = (a, b) => {
    // Custom sorting logic
    const rolesOrder = {
      admin: 1,
      "department_head": 2,
      user: 3,
    };

    const roleA = a.role;
    const roleB = b.role;

    if (rolesOrder[roleA] < rolesOrder[roleB]) {
      return 1;
    }
    if (rolesOrder[roleA] > rolesOrder[roleB]) {
      return -1;
    }
    return 0;
  };


  const handleEditClose = (shouldRefresh) => {
    setSelectedMember(undefined);
    setShowInviteModal(false);
    if (shouldRefresh) {
      getInvitations()
    }
  };

  const handleInviteRetry = async (data) => {

    try {
      let endpoint = `/organization/${activeOrganization?.id}/team/invitation/${data.id}/resend`;
      const { message } = await Api.Post(endpoint);
      Notify.success(message);

    } catch (error) {
      if (error.response && error.response.status === 422) {
        Notify.error(
          error.response.data.message
        );
      } else {
        console.error(error);
        Notify.error("Something went wrong");
      }
    }
  };

  const handleEntityEdit = (data) => {
    setSelectedMember(data);
    setShowInviteModal(true);
  };

  const columns = [
    {
      title: "Member",
      dataIndex: "name",
      render: (_, record) => <TeamMember invite={record} />,
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (text) => (<p className="capitalize">{text.replace('_', ' ')}</p>),
      sorter: roleSorter,
    },
    {
      title: "Department",
      dataIndex: "department",
      render: (department) => {
        if (Array.isArray(department)) {
          return department.map(item => (
            <Tag key={item.id}>{item.name}</Tag>
          ));
        } else if (typeof department === 'object') {
          return <Tag>{department.name}</Tag>;
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Actions",
      render: (_, record) => (
        <div className="flex gap-2">
          <Tooltip title="Resend Invite" className="text-lg  text-primary">
            <Button
              type="ghost"
              onClick={() => handleInviteRetry(record)}
              className="p-1 flex justify-center items-center"
            >
              <RedoOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Edit" className="text-primary text-lg">
            <Button
              type="ghost"
              onClick={() => handleEntityEdit(record)}
              className="p-1 flex justify-center items-center"
            >
              <EditOutlined />
            </Button>
          </Tooltip>

          {
            (activeOrganization.role === 'admin' && !record?.undeletable) &&
            (
              <Tooltip title="Delete" className="text-lg">
                <Button
                  onClick={() => handleDeleteModal(record)}
                  type="ghost"
                  className="p-1 flex justify-center items-center text-red-500 "
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            )
          }
        </div>
      ),
    },
  ];

  useEffect(() => {
    getInvitations()
  }, [getInvitations])

  useEffect(() => {
    onCountChange(records.length ?? 0)
  }, [records, onCountChange])

  return (
    <div className="overflow-auto bg-white rounded-md p-2 mt-3">
      <Table
        columns={columns}
        dataSource={records}
        bordered="true"
        loading={loading}
      />

      <DeleteModal
        onClose={handleDeleteClose}
        open={showDeleteModal}
        entity={selectedMember}
        mode={'invitations'}
      />

      <InviteModal
        onClose={handleEditClose}
        open={showInviteModal}
        invite={selectedMember}
      />

    </div>
  );
};
