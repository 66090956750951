import { has } from "lodash";
import { FaArrowUp } from "react-icons/fa6";
import { IoLockClosed } from "react-icons/io5";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';

export default function ProjectReports() {

  const { activeOrganization } = useSelector((state) => state.auth);
  const { plan } = activeOrganization;
  const { features } = plan;

  const reports = [
    {
      heading: 'Project Report by Department',
      description: 'Evaluate project performance and time spent by department',
      link: 'project/department',
      locked: !has(features, 'project-report-by-department')
    },
    {
      heading: 'Project Report By Member',
      description: 'Evaluate project performance and time spent by team member',
      link: 'project/member',
      locked: !has(features, 'project-report-by-member')
    }
  ];

  return (
    <div className='bg-white p-6 rounded-lg'>
      <h1 className='xl:text-lg text-lg lg:text-lg my-4 font-slightly-bold  font-poppins '>
        Project Reports
      </h1>
      <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5'>
        {
          reports.map(report => (
            <Link
              className='col-span-1 font-poppins bg-[#FAFAFA] p-3 rounded-md cursor-pointer'
              to={report.locked ? '#' : report.link}>
              <div className='flex justify-between'>
                <p className='font-semibold text-lg'>{report.heading}</p>
                <div
                  className='p-2 bg-[#3376FF] bg-opacity-10 rounded-full'
                  style={{ transform: `${!report.locked && 'rotate(45deg)'}` }}
                >
                  {
                    report.locked ?
                      <IoLockClosed size={20} /> :
                      <FaArrowUp size={20} />
                  }
                </div>
              </div>
              <div className='mt-4 mb-2 text-[#818181] text-left  '>
                {report.description}
              </div>
            </Link>
          ))
        }
      </div>
    </div>
  )
}
