import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function Subscriber({ children }) {

  const { activeOrganization } = useSelector((state) => state.auth)
  const navigate = useNavigate();

  useEffect(() => {
    if (activeOrganization?.role === 'admin' && !activeOrganization?.plan) {
      navigate('/plans');
    }
  }, [activeOrganization, navigate]);

  return (
    <>
      {activeOrganization?.plan && children}
    </>
  )
}
