
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getTrackerAppUrl = createAsyncThunk(
  'global/trackerApp',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('https://api.github.com/repos/saqib-nb/workotick-updates/releases/latest')
      return response
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

