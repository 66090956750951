import React, { useState } from "react";
import MainLayout from "../../layouts/MainLayout";
import { LeftOutlined } from "@ant-design/icons";
import { Select, Button } from "antd";
import Member from "../../assets/member1.png";
import CSV from "../../assets/download-csv.png";
import { useNavigate } from "react-router-dom";
import ActiveTimeTable from "./ActiveTimeTable";
import CustomDatePicker from "../CustomDatePicker";
export default function ActiveTime() {
  const navigate = useNavigate();

  const data = [
    {
      name: "Hassan",
      photo: Member,
      active: "07:20",
      clockin: "10:10 AM",
      project: "Workotick",
      log: "05:22",
      clockout: "8:10 PM",
      remaining: "01:40",
      department: "Design",
    },
    {
      name: "Azhar",
      photo: Member,
      active: "07:20",
      clockin: "10:10 AM",
      project: "Workotick",
      log: "05:22",
      clockout: "8:10 PM",
      remaining: "01:40",
      department: "Design",
    },
    {
      name: "Zahid",
      photo: Member,
      active: "07:20",
      clockin: "10:10 AM",
      project: "Workotick",
      log: "05:22",
      clockout: "8:10 PM",
      remaining: "01:40",
      department: "Development",
    },
    {
      name: "Hassan",
      photo: Member,
      active: "07:20",
      clockin: "10:10 AM",
      project: "paybag",
      log: "05:22",
      clockout: "8:10 PM",
      remaining: "01:40",
      department: "QA",
    },
    {
      name: "Sajid",
      photo: Member,
      active: "07:20",
      clockin: "10:10 AM",
      project: "Workotick",
      log: "05:22",
      clockout: "8:10 PM",
      remaining: "01:40",
      department: "Design",
    },
  ];
  const [filters, setFilters] = useState({
    department: [],
    name: [],
  });

  const handleChange = (value, type) => {
    setFilters((prevFilters) => ({ ...prevFilters, [type]: value }));
  };

  const clearState = (type) => {
    setFilters((prevFilters) => ({ ...prevFilters, [type]: null }));
  };

  //const filteredData = useFilter(data, filters);

  const MemberOptions = [...new Set(data.map((item) => item.name))];
  const departmentOptions = [...new Set(data.map((item) => item.department))];

  return (
    <MainLayout>
      <div className='p-1 md:p-5 py-2'>
        <div className='flex flex-col '>
          <div className='flex flex-row items-center gap-4'>
            <button
              className='p-1 px-2 rounded-md border bg-white'
              onClick={() => navigate("/reports")}>
              <LeftOutlined />
            </button>
            <h1 className='xl:text-2xl text-2xl lg:text-xl my-4 font-semibold font-poppins'>
              Active Time
            </h1>
          </div>
          <div className='flex gap-2 ml-2 main flex-col'>
            <div className='flex  gap-2 flex-col xl:flex-row justify-end mr-2'>
              <div className='flex gap-2'>
                <Select
                  placeholder='Team Member'
                  value={filters.name}
                  mode='multiple'
                  onChange={(value) => handleChange(value, "name")}
                  onClear={() => clearState("name")}
                  allowClear='true'
                  className='flex-grow w-56 h-fit'
                  options={MemberOptions.map((project) => ({
                    value: project,
                    label: project,
                  }))}
                />
                <Select
                  value={filters.department}
                  placeholder='Department'
                  onChange={(value) => handleChange(value, "department")}
                  mode='multiple'
                  onClear={() => clearState("department")}
                  allowClear='true'
                  className='flex-grow w-56 h-fit'
                  options={departmentOptions.map((department) => ({
                    value: department,
                    label: department,
                  }))}
                />
              </div>
              <div className='flex gap-2  md:justify-end h-8'>
                <div className='flex gap-2 '>
                  <CustomDatePicker />
                </div>
                <div>
                  <Button className='md:flex gap-2 h-8 items-center hidden bg-white'>
                    <img
                      src={CSV}
                      alt='download-icon'
                    />
                    <p className='text-[#9DA5B5]'>CSV</p>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ActiveTimeTable data={data} />
        <div></div>
      </div>
    </MainLayout>
  );
}
