import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const RoleGuard = ({ Component }) => {
  const { activeOrganization } = useSelector((state) => state.auth);
  const allowedRoles = ["admin"];

  const allowed = activeOrganization && allowedRoles.includes(activeOrganization?.role);

  return allowed ? <Component /> : <Navigate to="/" />;
};

export default RoleGuard;
