import React, { useState } from 'react';
import { DatePicker } from 'antd';
import CalenderIcon from '../assets/calender.svg';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

export default function CustomDateRangePicker({ onChange = () => { } }) {

  const today = dayjs();
  const lastDayOfCurrentMonth = today.endOf('month');
  const lastDayOfPreviousMonth = today.startOf('month');

  const [selectedDate, setSelectedDate] = useState([lastDayOfPreviousMonth, lastDayOfCurrentMonth]);

  const handleOnChange = (dates, dateStrings) => {
    setSelectedDate(dates);
    onChange(dates);
  };

  const handleAddDay = () => {
    setSelectedDate((prevDates) => [
      prevDates[0].add(1, 'day'),
      prevDates[1].add(1, 'day'),
    ]);
  };

  const handleSubtractDay = () => {
    setSelectedDate((prevDates) => [
      prevDates[0].subtract(1, 'day'),
      prevDates[1].subtract(1, 'day'),
    ]);
  };

  return (
    <div className='flex md:px-3 py-1 daterangepicker bg-white items-center px-1 cursor-pointer'>
      <img src={CalenderIcon} alt="calender-icon" />
      <RangePicker
        onChange={handleOnChange}
        format={'DD MMM, YYYY'}
        allowClear={false}
        value={selectedDate}
        autoFocus={false}
        suffixIcon
        separator="to"
      />
      <div>
        <button onClick={handleSubtractDay}><LeftOutlined /></button>
        <button onClick={handleAddDay}><RightOutlined /></button>
      </div>
    </div>
  );
}
