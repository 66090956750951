import { Button, Col, Form, Input, InputNumber, Modal, message as Notify, Row, Select, Switch, Typography } from 'antd'
import { useForm, useWatch } from 'antd/es/form/Form'
import React, { useCallback, useEffect, useState } from 'react'
import Api from '../../../utils/Axios';
import { useSelector } from 'react-redux';

export default function ProjectModal({ open, onClose, project = undefined }) {

  const { activeOrganization } = useSelector((state) => state.auth);
  const [projectForm] = useForm();
  const paymentMode = useWatch('payment_mode', projectForm)
  const monthlyRate = useWatch('monthly_rate', projectForm)
  const monthlyHours = useWatch('monthly_hours', projectForm)
  const [hourlyRate, setHourlyRate] = useState(0);

  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);

  const handleModalClose = useCallback((refresh) => {
    projectForm.resetFields();
    onClose(refresh)
  }, [onClose, projectForm])

  const getMetaInformation = useCallback(async () => {
    try {
      const { data } = await Api.Get(`organization/${activeOrganization?.id}/departments`)
      setDepartments(data.map(item => ({ label: item.name, value: item.id })))
    } catch (error) {
      console.error(error);
      Notify.error('Something went wrong')
    }
  }, [activeOrganization])

  const handleFormSubmit = useCallback(async (values) => {
    try {
      setLoading(true);
      let call;
      if (project) {
        call = () => Api.Put(`/organization/${activeOrganization?.id}/project/${project?.id}`, values);
      } else {
        call = () => Api.Post(`/organization/${activeOrganization?.id}/project`, values);
      }

      const { message } = await call();
      projectForm.resetFields();
      Notify.success(message);
      handleModalClose(true)
    } catch (error) {

      if (error.response) {
        if (error.response.status === 422) {
          const errors = error.response.data.errors;
          const validationErrors = Object.keys(errors).map((key) => ({
            name: key,
            errors: errors[key],
          }));
          projectForm.setFields(validationErrors);
        }
        if (error.response.status === 400) {
          Notify.error(error.response.data.message);
        }
      } else {
        console.error(error);
        Notify.error("Something went wrong");
      }

    } finally {
      setLoading(false)
    }
  }, [projectForm, activeOrganization, project, handleModalClose])

  useEffect(() => {
    setHourlyRate(parseFloat(monthlyRate) / parseFloat(monthlyHours))
  }, [monthlyRate, monthlyHours])

  useEffect(() => {
    getMetaInformation()
  }, [getMetaInformation])

  useEffect(() => {
    if (project) {
      projectForm.setFieldsValue({
        name: project.name,
        status: project.status === 'active' ? 1 : 0,
        payment_mode: project.payment_mode,
        hourly_rate: project.payment_mode,
        monthly_rate: project.monthly_rate,
        monthly_hours: project.monthly_hours,
        members: project.members.map(item => (item.id)),
        departments: project.departments.map(item => (item.id)),
        client_id: project.client_id === 0 ? '' : project.client_id
      })
    }
  }, [project, projectForm])

  return (
    <Modal
      open={open}
      onCancel={() => handleModalClose(false)}
      form={projectForm}
      footer={false}
      destroyOnClose
      width={600}
    >
      <h1 className='font-slightly-bold text-lg font-poppins'>{!project ? "Create Project" : "Update Project"}</h1>

      <Form layout='vertical' onFinish={handleFormSubmit} className='mt-5' form={projectForm}>
        <Row gutter={12}>
          <Col span={24} md={24}>
            <div>
              <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>
                Name
              </Typography.Paragraph>
              <Form.Item
                name='name'
                rules={[
                  { required: true, message: "Please input project name" },
                ]}>
                <Input
                  className='p-2'
                  placeholder='Project Name'
                />
              </Form.Item>
            </div>
          </Col>
          {/* <Col span={24} md={12}>
            <div>
              <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>
                Client
              </Typography.Paragraph>
              <Form.Item
                name='client_id'>
                <Select placeholder="Select client" size='large' options={clients} />
              </Form.Item>
            </div>
          </Col> */}
          {/* <Col span={24}>
						<div>
							<Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>
								Payment Mode
							</Typography.Paragraph>
							<Form.Item
								name='payment_mode'
								rules={[
									{ required: false, message: "Please select payment mode" },
								]}>
								<Select placeholder="Select payment mode" size='large' options={['Monthly', 'Hourly'].map(item => ({ label: item, value: item.toLocaleLowerCase() }))} />
							</Form.Item>
						</div>
					</Col> */}
          {
            paymentMode === 'hourly' && (
              <Col span={24}>
                <div>
                  <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>
                    Hourly Rate (USD)
                  </Typography.Paragraph>
                  <Form.Item
                    name='hourly_rate'
                    rules={[
                      { required: true, message: "Please enter hourly rates" },
                    ]}>
                    <InputNumber type='number' className='p-1 w-full' />
                  </Form.Item>
                </div>
              </Col>
            )
          }
          {
            paymentMode === 'monthly' && (
              <>
                <Col span={24} md={12}>
                  <div>
                    <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>
                      Monthly Retainer (USD)
                    </Typography.Paragraph>
                    <Form.Item
                      name='monthly_rate'
                      rules={[
                        { required: true, message: "Please enter monthly rates" },
                      ]}>
                      <InputNumber type='number' className='p-1 w-full' />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={24} md={12}>
                  <div>
                    <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>
                      Monthly Hours Limit
                    </Typography.Paragraph>
                    <Form.Item
                      name='monthly_hours'
                      rules={[
                        { required: true, message: "Please enter monthly rates" },
                      ]}>
                      <InputNumber type='number' className='p-1 w-full' />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={24} className='mb-3'>
                  <div>
                    <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>
                      Hourly Rate
                    </Typography.Paragraph>
                    <InputNumber type='number' className='p-1 w-full' disabled value={hourlyRate} />
                  </div>
                </Col>
              </>
            )
          }
          <Col span={24}>
            <div>
              <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>
                Assign Departments
              </Typography.Paragraph>
              <Form.Item
                name='departments'
                rules={[
                  { required: true, message: "Please select departments" },
                ]}>
                <Select
                  mode='multiple'
                  placeholder='Select departments'
                  size='large'
                  options={departments}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={24}>
            <div>
              <Typography.Paragraph className='font-poppins text-[#253053] font-slightly-bold text-sm mb-1.5'>
                Status
              </Typography.Paragraph>
              <Form.Item
                name='status'
              >
                <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Form.Item className='text-right'>
          <Button htmlType='submit' type='primary' loading={loading}>
            {project ? 'Update' : 'Create'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
