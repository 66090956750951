import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import { PinInput, PinInputField } from "@chakra-ui/react";
import { Typography, Button, message as Notify } from "antd";
import Api from "../../utils/Axios";
import { useSearchParams } from "react-router-dom";
import AuthLayout from "../../layouts/AuthLayout";
import Guest from "../../components/Middleware/Guest";

export default function Verify() {
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState(0);
  const [search] = useSearchParams();

  const handleOtpComplete = useCallback(
    async (code) => {
      try {
        setLoading(true);
        const { data } = await Api.Post("/otp-verify", { code });
        window.location.href = data.link;
      } catch (error) {
        if (error.response && error.response.status === 422) {
          const errorMessage = error.response.data.message;
          if (error.response.data?.data?.expired) {
            setMinutes(0)
            setSeconds(0)
          }
          Notify.error(errorMessage);
          setPin("");
        } else {
          console.error(error);
          Notify.error("Something went wrong");
        }
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const resendOTP = async () => {
    try {
      setLoading(true)
      const { message } = await Api.Post('/forget-password', {
        email: search.get('email')
      })
      Notify.success(message);
      setMinutes(3)
      setSeconds(0)
    } catch (error) {
      if (error.response && error.response.status === 422) {
        Notify.error(error.response.data.message);
      } else {
        console.error(error);
        Notify.error('Something went wrong');
      }
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);

  return (
    <Guest>
      <AuthLayout>
        <div className="mt-10 mb-5">
          <Typography.Title
            level={2}
            className="text-left text-dark font-semibold font-poppins"
          >
            Check Your <span className="text-secondary">Inbox!</span>
          </Typography.Title>
          <Typography.Paragraph className="font-poppins text-left text-[#636B83]  text-base">
            We've sent a one-time password to your email for verification. Please
            enter it below to proceed with resetting your password.
          </Typography.Paragraph>
        </div>

        <div className="flex justify-start">
          <PinInput
            placeholder=""
            size="lg"
            onChange={(value) => setPin(value)}
            onComplete={handleOtpComplete}
            value={pin}
            isDisabled={loading}
          >
            <PinInputField flex="1" mr="3" />
            <PinInputField flex="1" mx="3" />
            <PinInputField flex="1" mx="3" />
            <PinInputField flex="1" mx="3" />
            <PinInputField flex="1" ml="3" />
            <PinInputField flex="1" ml="3" />
          </PinInput>
        </div>
        <div className="flex justify-between items-center my-5">
          <Button
            type="link"
            disabled={seconds > 0 || minutes > 0}
            onClick={() => resendOTP()}
            className={`font-poppins text-dark px-0 ${seconds > 0 || minutes > 0
              ? "opacity-50 cursor-not-allowed"
              : "hover:text-secondary"
              }`}
          >
            Resend Code
          </Button>
          <Typography.Paragraph className="text-primary font-poppins text-right mb-0">
            {minutes < 10 ? `0${minutes}` : minutes}:
            {seconds < 10 ? `0${seconds}` : seconds}
          </Typography.Paragraph>
        </div>
      </AuthLayout>
    </Guest>
  );
}
