import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

export default function AdminView({ children }) {

  const navigate = useNavigate();
  const { activeOrganization } = useSelector((state) => state.auth);

  useEffect(() => {
    if (activeOrganization && activeOrganization.role !== 'admin') {
      navigate(-1)
    }
  }, [activeOrganization, navigate]);

  return (
    <div>{activeOrganization?.role === 'admin' && children}</div>
  )
}
