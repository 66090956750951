import { Button, Modal, message } from 'antd'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux';
import Api from '../../utils/Axios';

export default function DeleteScreenshotModal({ open, screenshot, onClose }) {

  const { activeOrganization } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);
      await Api.Delete(`organization/${activeOrganization.id}/screenshot/${screenshot.id}`);
      message.success('Screenshot deleted successfully');
      onClose(true);
    } catch (error) {
      if(error.response && error.response.status === 403) {
        message.error(error.response.data.message);
      } else {
        console.error(error)
        message.error('Something went wrong');
      }
    } finally {
      setLoading(false);
    }
  }, [screenshot, activeOrganization, onClose]);

  return (
    <Modal open={open} onCancel={onClose} footer={false} centered>
      <div className="md:m-10 flex flex-col items-center text-center font-poppins">
        <p className="font-slightly-bold text-2xl my-2">
          Are you sure you want to delete this screenshot?
        </p>
        <p className="text-smtext font-slightly-bold">
          Deleting the screenshot will also delete the time entry from logged in time
        </p>
        <div className="flex gap-2">
          <Button
            type="primary"
            className="bg-primary  h-9 mt-5 rounded-sm w-40 font-poppins"
            onClick={handleDelete}
            loading={loading}
          >
            Delete
          </Button>
          <Button
            type="primary"
            className="bg-primary w-40 h-9 mt-5 rounded-sm  font-poppins"
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}
