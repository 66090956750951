import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../components/Layout/Navbar'
import Icon from "../assets/workotic-logo-icon.svg";
import workotic from "../assets/workotic-logo.svg";

export default function SecondaryLayout({ children }) {
  return (
    <div>
      <div className='flex py-2 lg:ml-14'>
        <div className='hidden md:block'>
          <Link to="/">
            <img
              src={workotic}
              alt='logo'
              className='w-40 xl:w-48 p-2'
            />
          </Link>
        </div>
        <div className='block md:hidden'>
          <img
            src={Icon}
            alt='logo'
            className=''
          />
        </div>
        <Navbar />
      </div>
      {children}
    </div>
  )
}
