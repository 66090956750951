import { Button, Empty } from "antd";
import { useNavigate } from "react-router-dom";
import Screenshot from "../Screenshots/Screenshot";
import DetailModal from "../Screenshots/DetailModal";
import { useCallback, useEffect, useState } from "react";

export default function RecentActivity({ activities }) {

  const [screenshots, setScreenshots] = useState([]);
  const [selectedScreenshot, setSelectedScreenshot] = useState(undefined);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const navigate = useNavigate();

  const handleShowScreenshot = useCallback((screenshot) => {
    setSelectedScreenshot(screenshot);
    setShowDetailModal(true);
  }, [])

  const handleSlider = useCallback((type) => {
    const index = screenshots.findIndex(item => item.id === selectedScreenshot?.id);

    if (index > -1) {
      let newScreenshot;
      if (type === 'next') {
        newScreenshot = screenshots[index + 1];
      } else {
        newScreenshot = screenshots[index - 1];
      }
      if(newScreenshot) {
        setSelectedScreenshot(newScreenshot)
      }
    }
  }, [selectedScreenshot, screenshots])

  useEffect(() => {
    if (activities) {
      setScreenshots(activities);
    }
  }, [activities])

  return (
    <div className="rounded bg-white mt-5 p-4">
      <div className="flex justify-between ">
        <p className="lg:text-xl font-medium mt-1">Recent Activity</p>
        <Button className="bg-[#3376FF] px-6 py-1 text-white rounded-md h-10 border-0" onClick={() => navigate('/screenshots')}>
          View All
        </Button>
      </div>
      <div className="grid grid-cols-3 gap-3 my-7">
        {activities.length <= 0 && (
          <Empty description="No activity performed yet" />
        )}
        {activities.map((item) => (
          <Screenshot screenshot={item} onClick={() => handleShowScreenshot(item)} />
        ))}

        <DetailModal
          onClose={() => setShowDetailModal(false)}
          open={showDetailModal}
          screenshot={selectedScreenshot}
          onNext={() => handleSlider('next')}
          onPrev={() => handleSlider('prev')}
        />
      </div>
    </div>
  );
}