import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, Button, Form, Input, message as Notify } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import PasswordStrengthBar from "react-password-strength-bar";
import Api from "../../utils/Axios";
import AuthLayout from "../../layouts/AuthLayout";
import Guest from "../../components/Middleware/Guest";

export default function NewPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [resetForm] = useForm();
  const { search } = useLocation();
  const password = useWatch("password", resetForm);

  const handleFormSubmit = useCallback(
    async (values) => {
      try {
        const searchParams = new URLSearchParams(search);
        setLoading(true);
        const { message } = await Api.Post("/reset-password", {
          ...values,
          token: searchParams.get('token'),
        });
        Notify.success(message);
        navigate("/login");
      } catch (error) {
        if (error.response) {
          if (error.response.status === 422) {
            const errors = error.response.data.errors;
            const validationErrors = Object.keys(errors).map((key) => ({
              name: key,
              errors: errors[key],
            }));
            resetForm.setFields(validationErrors);
          } else if (error.response.status === 403) {
            Notify.error(error.response.data.message);
          }
        } else {
          console.error(error);
          Notify.error("Something went wrong");
        }
      } finally {
        setLoading(false);
      }
    },
    [navigate, resetForm, search]
  );

  return (
    <Guest>
      <AuthLayout>
        <div className="mt-10 mb-5">
          <Typography.Title
            level={2}
            className="text-left text-dark font-semibold font-poppins"
          >
            Create new <span className="text-secondary">password</span>
          </Typography.Title>
          <Typography.Paragraph className="font-poppins font-light">
            Please create a new password to ensure your account remains secure.
            Make sure it's strong and memorable for you.
          </Typography.Paragraph>
        </div>
        <Form
          layout="vertical"
          className="mt-0 "
          onFinish={handleFormSubmit}
          form={resetForm}
          disabled={loading}
        >
          <div className="">
            <Typography.Paragraph className="font-poppins text-dark text-sm mb-1.5">
              New Password*
            </Typography.Paragraph>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "Please enter new password" }]}
            >
              <Input.Password
                className="w-full px-4 py-3 border rounded-md"
                placeholder="*******"
              />
            </Form.Item>
          </div>
          <div className="">
            <Typography.Paragraph className="font-poppins text-dark text-sm mb-1.5">
              Confirm New Password*
            </Typography.Paragraph>
            <Form.Item
              name="password_confirmation"
              rules={[{ required: true, message: "Re enter new password" }]}
            >
              <Input.Password
                name="password"
                className="w-full px-4 py-3 border rounded-md"
                placeholder="*******"
              />
            </Form.Item>
            <PasswordStrengthBar
              className="password-strength-checker"
              password={password}
              barColors={["#ddd", "#ddd", "#ef4836", "#f6b44d", "#53B12F"]}
              scoreWords={[
                "😭",
                "Too weak 😟",
                "Weak 😕",
                "Normal 🙂",
                "Strong 😎",
              ]}
            />
          </div>
          <Form.Item className="mt-8">
            <Button
              type="primary"
              htmlType="submit"
              className="w-full h-12 bg-primary text-white font-poppins text-medium"
              loading={loading}
            >
              Confirm
            </Button>
          </Form.Item>
        </Form>
      </AuthLayout>
    </Guest>
  );
}
