import './App.css';
import Routes from './Routes';
import updateLocale from 'dayjs/plugin/updateLocale';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import dayjs from 'dayjs';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTrackerAppUrl } from './features/globalAction';

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(updateLocale);
dayjs.extend(isSameOrBefore);
dayjs.updateLocale('en', {
  weekStart: 1,
});

function App() {

  const { activeOrganization } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const getTracker = useCallback(async () => {
    await dispatch(getTrackerAppUrl())
  }, [dispatch])

  useEffect(() => {
    getTracker()
  }, [getTracker])


  useEffect(() => {
    dayjs.tz.setDefault(activeOrganization?.timezone ?? 'utc');
  }, [activeOrganization])

  return (
    <div className="App font-poppins">
      <Routes />
    </div>
  );
}

export default App;
