import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function Guest({ children }) {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isAuthenticated } = useSelector(state => state.auth);

  useEffect(() => {
    if (isAuthenticated && !searchParams.has('token')) {
      navigate('/')
    }
  }, [isAuthenticated, navigate, searchParams])

  return <div>{children}</div>;
};
