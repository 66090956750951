import { Button, Form, Radio, Select, Switch, Tooltip, Typography, message } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { FaCrown } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import { HiMiniQuestionMarkCircle } from "react-icons/hi2";
import Api from '../../../../utils/Axios';
import { getUserDetails } from '../../../../features/auth/authActions';
import { has, includes } from 'lodash';

const intervals = [
  {
    value: 2,
    premium: true,
  },
  {
    value: 5,
    premium: false,
  },
  {
    value: 10,
    premium: false,
  },
  {
    value: 20,
    premium: false,
  }
];

const timeouts = [
  {
    value: 10,
    label: '10 minutes'
  },
  {
    value: 30,
    label: '30 minutes'
  },
  {
    value: 60,
    label: '60 minutes'
  }
]

export default function Screenshots() {

  const [screenshotSettings] = useForm();
  const { activeOrganization } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const { plan: { features } } = activeOrganization;
  const visibility = useWatch('screenshot_mode', screenshotSettings);
  const has_timeout = useWatch('has_timeout', screenshotSettings);
  const dispatch = useDispatch();

  const allowedIntervals = features['screenshot-intervals'].split('|');
  const hasInterval = (value) => {
    return includes(allowedIntervals, value);
  }

  const handleFormSubmit = useCallback(async (values) => {
    try {
      console.log(values);
      setLoading(true);
      await Api.Put(`organization/${activeOrganization.id}/screenshot-setting`, {
        ...values,
        screenshot_mode: values.screenshot_mode ? 'clear' : 'blur'
      });
      await dispatch(getUserDetails());
      message.success('Settings updated successfully');
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const errorMessages = error.response.data.errors;
        const validationErrors = Object.keys(errorMessages).map((key) => ({
          name: key,
          errors: errorMessages[key],
        }));
        screenshotSettings.setFields(validationErrors);
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [screenshotSettings, activeOrganization, dispatch]);

  useEffect(() => {
    screenshotSettings.setFieldsValue({
      screenshot_mode: activeOrganization.screenshot_mode === 'blur' ? false : true,
      screenshot_interval: (parseInt(activeOrganization.screenshot_interval) / 60),
      idle_duration: activeOrganization.idle_duration,
      has_timeout: (activeOrganization.idle_duration && has(features, 'idle-timeout')) ? true : false
    })
  }, [activeOrganization, screenshotSettings, features])

  return (
    <div className="bg-white rounded-md p-5 h-full">
      <Form form={screenshotSettings} onFinish={handleFormSubmit} disabled={loading}>
        <div className="">
          <Typography.Paragraph className='font-poppins font-semibold'>
            Visibility
          </Typography.Paragraph>
          <div className="flex items-center gap-x-5">
            <span className={clsx({
              'font-poppins flex gap-x-2': true,
              'font-semibold': !visibility
            })}> {
                !has(features, 'clear-screenshots') && (
                  <div className='z-[99999] flex gap-x-2 mr-3'>
                    <Tooltip title="Upgrade your plan to avail this feature">
                      <HiMiniQuestionMarkCircle color='#000000' size={20} />
                    </Tooltip>
                    <FaCrown color='#FFC700' size={20} />
                  </div>
                )
              } Blur</span>
            <Form.Item name='screenshot_mode' noStyle>
              <Switch disabled={!has(features, 'clear-screenshots')} />
            </Form.Item>
            <span className={clsx({
              'font-poppins': true,
              'font-semibold': visibility
            })}>Clear</span>
          </div>
        </div>
        <div className="mt-5">
          <Typography.Paragraph className='font-poppins font-semibold'>
            Screenshot Intervals
          </Typography.Paragraph>
          <Form.Item name='screenshot_interval'>
            <Radio.Group className='flex'>
              {
                intervals.map(item => (
                  <div className='flex'>
                    <Radio key={`interval-${item.value}`} value={item.value} disabled={!hasInterval('2')}>
                      <div className="flex gap-x-3 items-center">
                        {item.value} minutes
                      </div>
                    </Radio>
                    {
                      !hasInterval('2') && (
                        <div className='z-[99999] flex gap-x-2 mr-3'>
                          <Tooltip title="Upgrade your plan to avail this feature">
                            <HiMiniQuestionMarkCircle color='#000000' size={20} />
                          </Tooltip>
                          <FaCrown color='#FFC700' size={20} />
                        </div>
                      )
                    }
                  </div>
                ))
              }
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="">
          <div className="flex gap-x-2">
            <Typography.Paragraph className='font-poppins font-semibold'>
              Idle Duration
            </Typography.Paragraph>
            {
              !has(features, 'idle-timeout') && (
                <div className='z-[99999] flex gap-x-2 mr-3'>
                  <Tooltip title="Upgrade your plan to avail this feature">
                    <HiMiniQuestionMarkCircle color='#000000' size={20} />
                  </Tooltip>
                  <FaCrown color='#FFC700' size={20} />
                </div>
              )
            }

          </div>
          <div className='flex gap-x-3 items-center mb-3'>
            <div>
              <Form.Item name='has_timeout'>
                <Switch disabled={!has(features, 'idle-timeout')} />
              </Form.Item>
              {
                (has_timeout && has(features, 'idle-timeout')) &&
                <Form.Item name='idle_duration' noStyle >
                  <Select options={timeouts} placeholder='Select duration'/>
                </Form.Item>
              }
            </div>

          </div>

        </div>
        <div className="text-center">
          <Button htmlType='submit' className='h-10 w-36' type='primary font-poppins' loading={loading}>
            Update
          </Button>
        </div>
      </Form>
    </div>
  )
}
