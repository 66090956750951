import React, { useCallback, useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";
import TimeSummaryData from "./TimeSummaryData";
import { useSelector } from "react-redux";
import Api from "../../utils/Axios";
import TimeSummaryLoader from "../Skeletons/TimeSummaryLoader";
import LazyLoad from "../LazyLoad";

export default function TimeSummary({ date }) {

  const [loading, setLoading] = useState(false);
  const [timeLog, setTimeLog] = useState([]);
  const [chartData, setChartData] = useState(null);
  const { activeOrganization, user } = useSelector((state) => state.auth);

  const state = useMemo(() => {
    return {
      series: [{
        name: user.name,
        data: timeLog?.chart_data?.dataset ?? []
      }],
      options: {
        chart: {
          type: 'area',
          stacked: false,
          height: 350,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 0.5,
            stops: [0, 90, 100]
          },
        },
        yaxis: {
          categories: [],
          labels: {
            formatter: function (val) {
              return val;
            },
          },
        },
        xaxis: {
          categories: timeLog?.chart_data?.labels ?? [],
          type: 'time',
        },
      },
    };
  }, [timeLog, user]);

  const getTimeLog = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await Api.Post(
        `/organization/${activeOrganization?.id}/my-report/time-summary`,
        {
          date: date.toISOString()
        }
      );
      setTimeLog(data);
    } catch (error) {
      console.error('Something went wrong');
    } finally {
      setLoading(false);
    }
  }, [activeOrganization, date]);

  useEffect(() => {
    setChartData(state);
  }, [state]);

  useEffect(() => {
    getTimeLog()
  }, [getTimeLog])

  return (
    <LazyLoad loading={loading} loader={<TimeSummaryLoader />}>
      <div className="bg-white px-5 pt-5 my-3 rounded-md time-summary">
        <h1 className="text-lg font-semibold mt-1">Time Summary</h1>
        <TimeSummaryData projects={timeLog?.projects ?? []} total={timeLog?.total ?? null} />
        <h1 className="text-lg font-semibold mt-1">Activity Chart</h1>
        {chartData && (
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="area"
            width="100%"
          />
        )}
      </div>
    </LazyLoad>

  );
}
