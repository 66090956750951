import React from "react";
import { Table } from "antd";

const ProjectPerformanceTable = ({ projectData }) => {
  const dates = [
    ...new Set(
      projectData.flatMap((project) => project.data.map((item) => item.date))
    ),
  ];
  const days = [
    ...new Set(
      projectData.flatMap((project) => project.data.map((item) => item.day))
    ),
  ];

  const columns = [
    {
      title: "Project",
      dataIndex: "projectName",
      align: "center",
      key: "projectName",
    },
    ...dates.map((date) => ({
      title: (
        <div className='text-center flex flex-col items-center '>
          <p>{days[dates.indexOf(date)]}</p>
          <p>{date}</p>
        </div>
      ),
      align: "center",
      dataIndex: date,
      key: date,
      render: (value) => (
        <div className='flex justify-center items-center'>
          <p>{value !== undefined ? value : "-"}</p>{" "}
        </div>
      ),
    })),
  ];

  const getTableData = () => {
    const tableData = [];
    projectData.forEach((project, index) => {
      const rowData = {
        key: `${project.name}-${index + 1}`,
        projectName: project.name,
      };

      project.data.forEach((item) => {
        rowData[item.date] = item.timeSpent;
      });

      tableData.push(rowData);
    });

    return tableData;
  };

  return (
    <div className='overflow-auto bg-white rounded-md p-2 mt-3'>
      <Table
        dataSource={getTableData()}
        columns={columns}
        pagination={true}
        className='m-2 text-center'
      />
    </div>
  );
};

export default ProjectPerformanceTable;
