import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Typography, message as Notify } from "antd";
import Api from "../../utils/Axios";
import AuthLayout from "../../layouts/AuthLayout";
import Auth from "../../components/Middleware/Auth";
import { useSelector } from "react-redux";

export default function Invitation() {

  const { isAuthenticated } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState(undefined);
  const [token, setToken] = useState("");
  const [searchParams] = useSearchParams();

  const fetchOrganization = useCallback(async (token) => {
    try {
      if(!isAuthenticated) return false
      setToken(token);
      const { data } = await Api.Post("/organization/validate-token", {
        token,
      });
      setOrganization(data);
    } catch (error) {
      console.error(error);
    }
  }, [isAuthenticated]);

  const handleAcceptInvite = useCallback(async () => {
    try {
      setLoading(true);
      const { message } = await Api.Post("/organization/join", { token });
      Notify.success(message);
      navigate("/organization");
    } catch (error) {
      if (error.response && error.response.data.status === 403) {
        Notify.error(error.response.data.message);
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [navigate, token]);

  useEffect(() => {
    if (searchParams.has("token") && searchParams.get("token") !== "") {
      fetchOrganization(searchParams.get("token"));
    }
  }, [searchParams, fetchOrganization]);

  return (
    <Auth>
      <AuthLayout>
        <div className="flex flex-col h-full mt-12">
          {/* headings */}
          <div>
            <img
              src={organization?.logo}
              alt="company-logo"
              width={70}
              className="my-4 rounded-full h-16 w-16 border-[3px] object-cover border-solid border-blue-500"
            />
            <Typography.Title
              level={2}
              className="text-left text-dark font-semibold font-poppins"
            >
              Join <span className="text-secondary">{organization?.name}</span>
            </Typography.Title>
            <p className="my-3 text-left text-[#636B83] font-poppins">
              You have been invited to join {organization?.name}
            </p>
            <p className="my-3 text-left text-[#636B83] font-poppins">
              By accepting this invitation, you’re about to integrate into a
              user-centric workspace designed to enhance your productivity and
              streamline your time management.
            </p>
          </div>
          {/* invite text */}
          <div className="mt-5">
            <Button
              type="primary"
              onClick={handleAcceptInvite}
              className="w-full h-12  bg-primary text-white font-poppins text-medium"
              loading={loading}
            >
              Accept invitation
            </Button>
          </div>
        </div>
      </AuthLayout>
    </Auth>
  );
}
