import React from "react";

function DepartmentIcon({ selected, value, onChange, size = "medium" }) {
  const sizes = {
    small: 33,
    medium: 40,
  };

  return (
    <div
      className='flex justify-center align-center rounded-md overflow-hidden cursor-pointer'
      style={{
        background: `${value}20`,
      }}
      onClick={() => onChange(value)}>
      <svg
        width={sizes[size]}
        height={sizes[size]}
        viewBox='0 0 44 44'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        {selected && (
          <>
            <g mask='url(#mask0_1105_560)'>
              <rect
                x='13.125'
                y='-4.76187'
                width='44'
                height='49.4795'
                rx='6'
                transform='rotate(-45 13.125 -4.76187)'
                fill={value}
              />
            </g>
            <path
              d='M40 3.73602L33.1429 10L30 7.12901L30.8057 6.39299L33.1429 8.52274L39.1943 3L40 3.73602Z'
              fill='white'
            />
          </>
        )}
        <path
          opacity='0.587821'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.5527 15.4444C14.5527 17.899 16.5426 19.8889 18.9972 19.8889C21.4518 19.8889 23.4416 17.899 23.4416 15.4444C23.4416 12.9898 21.4518 11 18.9972 11C16.5426 11 14.5527 12.9898 14.5527 15.4444ZM25.6596 19.8891C25.6596 21.73 27.152 23.2224 28.993 23.2224C30.8339 23.2224 32.3263 21.73 32.3263 19.8891C32.3263 18.0481 30.8339 16.5558 28.993 16.5558C27.152 16.5558 25.6596 18.0481 25.6596 19.8891Z'
          fill={value}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18.9815 22.111C13.7355 22.111 9.4314 24.8071 9.00072 30.1101C8.97726 30.399 9.52968 30.9999 9.80834 30.9999H28.163C28.9977 30.9999 29.0107 30.3282 28.9977 30.111C28.6721 24.659 24.3014 22.111 18.9815 22.111ZM35.0643 30.9999H30.7831V30.9996C30.7831 28.4987 29.9569 26.1908 28.5625 24.334C32.3459 24.3765 35.4346 26.2902 35.6671 30.3333C35.6765 30.4962 35.6671 30.9999 35.0643 30.9999Z'
          fill={value}
        />
      </svg>
    </div>
  );
}

export default DepartmentIcon;
