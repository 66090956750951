import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getUserDetails } from '../../features/auth/authActions';

export default function Auth({ children }) {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isAuthenticated } = useSelector(state => state.auth);
  const location = useLocation()
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate({
        pathname: '/login',
        search: `?${searchParams.toString()}&redirectTo=${location.pathname}?${searchParams.toString()}`
      })
    } else {
      dispatch(getUserDetails());
    }
  }, [isAuthenticated, dispatch, location, navigate, searchParams])

  return <>{isAuthenticated && children}</>;
};
