import React from "react";
import { Tabs } from "antd";
import "../index.css";
import MyReport from "./ReportPage/MyReport";
import TeamReport from "./ReportPage/TeamReport";
import { useSelector } from "react-redux";
export default function DashboardTabs() {
  const { activeOrganization } = useSelector((state) => state.auth);

  const tabs = [
    {
      label: "My Report",
      key: "1",
      children: <MyReport />,
    },
    ["admin", "department_head"].includes(activeOrganization?.role) && {
      label: "Team Report",
      key: "2",
      children: <TeamReport />,
    },
  ];

  return (
    <div className="dashboard w-full relative">
      <Tabs
        type="card"
        defaultActiveKey="1"
        items={tabs.map((tab) => {
          return {
            label: (
              <span className="pl-2 ">
                <button className="text-xl bg-blue-500 text-white rounded-md text-center font-poppins">
                  {tab.label}
                </button>
              </span>
            ),
            ...tab,
          };
        })}
      />
    </div>
  );
}
