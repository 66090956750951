import { Button, Form, Modal, Radio, Upload, message as Notify, message } from 'antd';
import { BsFillCloudUploadFill } from "react-icons/bs";
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import Api from '../../../../utils/Axios';
import { useSelector } from 'react-redux';
import { IoMdCopy } from "react-icons/io";
import './style.css'

export default function PaymentModal({ invoice = undefined, open, onClose }) {

  const { activeOrganization } = useSelector((state) => state.auth);
  const [paymentForm] = useForm();
  const [accounts, setAccounts] = useState([]);
  const [processing, setProcessing] = useState(false);

  const handleFileChange = useCallback(
    ({ file }) => {
      if (file.status === "done") {
        const { response } = file;
        paymentForm.setFieldValue('payment_slip', response);
      }
    },
    [paymentForm]
  );

  const handleFormSubmit = useCallback(async (values) => {
    if (!invoice) return false
    try {
      setProcessing(true);
      const { message } = await Api.Post(`organization/${activeOrganization.id}/invoice/${invoice.id}`, values);
      Notify.success(message);
      paymentForm.resetFields();
      onClose(true);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const errors = error.response.data.errors;
        const validationErrors = Object.keys(errors).map((key) => ({
          name: key,
          errors: errors[key],
        }));
        paymentForm.setFields(validationErrors);
      } else {
        console.error(error)
      }
    } finally {
      setProcessing(false);
    }
  }, [activeOrganization, invoice, onClose, paymentForm])

  const handleFileValidation = useCallback((file) => {
    const allowed = ['image/png', 'image/jpg', 'image/jpeg'];
    const canUpload = allowed.includes(file.type)

    if (!canUpload) {
      Notify.error('Only png, jpg and jpeg files are allowed');
    }

    return canUpload || Upload.LIST_IGNORE;

  }, [])

  const getAccounts = useCallback(async () => {
    try {
      const { data } = await Api.Get(`accounts`);
      setAccounts(data)
    } catch (error) {
      console.error('something went wrong');
    }
  }, [])

  const copyToClipboard = (text, title) => {
    navigator.clipboard.writeText(text).then(() => {
      message.success(`${title} copied to clipboard`);
    }).catch((err) => {
      console.error('Failed to copy text: ', err);
    });
  }

  useEffect(() => {
    getAccounts()
  }, [getAccounts])

  return (
    <Modal
      open={open}
      onCancel={() => onClose()}
      centered
      footer={false}
      destroyOnClose
      width={800}
    >
      <div className="mt-5 font-poppins">
        <h1 className='font-poppins text-lg mb-3 font-semibold'>
          Please make the payment to following accounts:
        </h1>
        <p className='text-sm my-2'>
          Amount: {invoice?.amount}
        </p>
        <h1 className='font-semibold text-base'>
          Account Details:
        </h1>
        <Form form={paymentForm} onFinish={handleFormSubmit} disabled={processing}>
          <Form.Item name='account_id'>
            <Radio.Group className='grid grid-cols-2 mt-5 font-poppins bank-account-checkbox-group'>
              {
                accounts.map((account, accountIndex) => (
                  <Radio value={account.id}>
                    <div key={accountIndex} className="col-span-1 shadow-md p-4 rounded-md space-y-3">
                      {
                        Object.keys(account).map((item, index) => {
                          if (item === 'id') return null
                          if (item === 'icon') {
                            return (<img key={index} src={account[item]} alt="bank icon" className='mb-3' />)
                          } else {
                            return (
                              <div key={index} className="flex gap-x-2 items-center">
                                <p className='inline-block font-semibold'>
                                  {item}:
                                </p>
                                <p className='flex gap-x-4 items-center'>
                                  {account[item]}
                                  {
                                    ['Account Number', 'IBAN Number'].includes(item.trim()) && (
                                      <Button icon={<IoMdCopy />} onClick={() => copyToClipboard(account[item], item)} />
                                    )
                                  }
                                </p>
                              </div>
                            )
                          }
                        })
                      }
                    </div>
                  </Radio>
                ))
              }
            </Radio.Group>
          </Form.Item>
          <div className="upload-wrapper mb-5 mt-8">
            <Form.Item name='payment_slip'>
              <Upload
                name="file"
                action={
                  process.env.REACT_APP_BACKEND_URL + "/upload"
                }
                headers={{
                  Authorization: `Bearer ${localStorage.getItem(
                    "token"
                  )}`,
                }}
                className='payment-slip-uploader'
                onChange={handleFileChange}
                beforeUpload={handleFileValidation}
              >
                <div className="flex justify-center items-center flex-col">
                  <BsFillCloudUploadFill size={60} color='#1D8DDC' />
                  <h1 className="text-lg text-primary font-poppins">Upload a payment slip</h1>
                  <p className='font-poppins text-[15px] font-normal text-center mt-2'>
                    Please upload the screenshot of payment slip for verification purpose. We usually verify in 20 minutes.
                  </p>
                </div>
              </Upload>
            </Form.Item>
            <Form.Item className='text-center'>
              <Button htmlType='submit' type='primary' className='px-5 h-10 font-poppins' loading={processing}>
                Upload
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal >
  )
}
