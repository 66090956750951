import { Table } from "antd";
import React from "react";

export default function InOutsTable({ data }) {
  const columns = [
    {
      title: "S.no",
      dataIndex: "sno",
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Department",
      dataIndex: "department",
      align: "center",
    },
    {
      title: "In at",
      dataIndex: "in",
      align: "center",
    },
    {
      title: "Out at",
      dataIndex: "out",
      align: "center",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      align: "center",
    },
  ];
  const getTableData = () => {
    const tableData = [];
    data.forEach((item, index) => {
      tableData.push({
        sno: index + 1,
        key: `${item.name}-${index + 1}`,
        name: item.name,
        duration: item.duration,
        in: item.in,
        department: item.department,
        out: item.out,
      });
    });
    return tableData;
  };

  return (
    <div className='overflow-auto bg-white rounded-md p-2 mt-3'>
      <Table
        dataSource={getTableData()}
        columns={columns}
        pagination={true}
        className='m-2'
      />
    </div>
  );
}
