import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Button, Table as AntTable, Tooltip, Tag } from 'antd';
import Api from '../../../utils/Axios';
import clsx from 'clsx';

export default function Table({ filters }) {

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState(undefined);
  const [recordFilters, setRecordFilters] = useState(undefined);
  const { activeOrganization } = useSelector((state) => state.auth);

  const getReport = useCallback(async () => {
    try {
      const { data: { data: records, pagination: paginationData } } = await Api.Get(`organization/${activeOrganization.id}/report/target-hours-tracker`, recordFilters)
      setData(records);
      setPagination(paginationData);
    } catch (error) {
      console.error(error);
    }
  }, [activeOrganization, recordFilters])

  const handleLoadMore = useCallback(async () => {
    const { data: { data: records, pagination: paginationData } } = await Api.Get(pagination?.links?.next, recordFilters)
    setData((prev) => [
      ...prev,
      ...records
    ]);
    setPagination(paginationData)
  }, [pagination, recordFilters])

  useEffect(() => {
    if (!filters) return

    setRecordFilters(filters);
  }, [filters])

  useEffect(() => {
    getReport()
  }, [getReport])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Department',
      dataIndex: 'department_names',
      key: 'department_names',
      render: (data) => {
        const firstTwoDepartments = data.slice(0, 2);
        const restDepartments = data.slice(2);
        return (
          <>
            {firstTwoDepartments.map((department, index) => (
              <Tag color='purple' className='font-poppins mt-1' key={index}>
                {department}
              </Tag>
            ))}
            {restDepartments.length > 0 && (
              <Tooltip title={restDepartments.map(department => department).join(', ')}>
                <Tag color='purple' className='font-poppins mt-1'>
                  +{restDepartments.length}
                </Tag>
              </Tooltip>
            )}
          </>
        )
      }
    },
    {
      title: 'Days',
      dataIndex: 'days',
      key: 'days',
    },
    {
      title: 'Target Hours',
      dataIndex: 'target_hours',
      key: 'target_hours',
      sorter: (a, b) => a.difference - b.difference
    },
    {
      title: 'Active Hours',
      dataIndex: 'logged_hours',
      key: 'logged_hours',
      sorter: (a, b) => a.difference - b.difference,
    },
    {
      title: 'Difference',
      dataIndex: 'difference',
      key: 'total_absents',
      render: (data, record) => (
        <p className={clsx({
          'text-red-500' : record.difference < 0,
          'text-green-500' : record.difference > 0,
          'text-orange-50' : record.difference === 0
        })}>{data}</p>
      ),
      sorter: (a, b) => a.difference - b.difference
    }
  ];

  return (
    <div className='overflow-auto bg-white rounded-md p-2 mt-3'>
      <AntTable
        dataSource={data}
        columns={columns}
        className='m-2'
        pagination={false}
      />

      {
        pagination?.links?.next && (
          <div className="load-more sticky flex justify-center top-0">
            <Button onClick={handleLoadMore} type="primary" className="font-poppins py-2 px-5 h-auto">Load More</Button>
          </div>
        )
      }
    </div>
  );
}
