import { Avatar, Badge } from 'antd'
import React from 'react'

import { UserOutlined } from '@ant-design/icons'

export default function Profile({ isActive, pic }) {
  return (
    <div className='flex items-center'>
       <Badge
        offset={["-5%", "75%"]}
        style={{
          width: "8px",
          height: "8px",
          backgroundColor: "#6CD818"
        }}
        dot={isActive}
      >
        <Avatar
          src={pic}
          size={40}
          shape="circle"
          icon={<UserOutlined />}
          style={{ borderRadius: "30px" }}
        />
      </Badge>
    </div>
  )
}
