import { Modal } from 'antd'
import { MdError } from "react-icons/md";

export default function DowngradeErrorModal({ open, errors, plan, onClose }) {

  if (!errors && !plan) return null;

  return (
    <Modal
      open={open}
      onCancel={onClose}
      centered
      destroyOnClose
      footer={false}
      width={500}
    >
      <div className="font-poppins mt-5">
        <h1 className='font-slightly-bold text-2xl my-5 text-center flex items-center gap-x-2 justify-center'>
          <MdError className='text-red-500' size={30} />
          Unable to downgrade
        </h1>
        <p className='text-'>
          You cannot downgrade your plan to <strong>{plan.name}</strong> because of following error(s):
        </p>
        <ul className='mt-5 list-decimal ml-5'>
          {
            errors?.map(item => (
              <li>{item}</li>
            ))
          }
        </ul>
      </div>
    </Modal>
  )
}
