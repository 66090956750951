import React, { useCallback, useEffect, useState } from "react";
import {
  Link, useNavigate,
  useSearchParams
} from "react-router-dom";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Typography,
  message as Notify,
} from "antd";
import PasswordStrengthBar from "react-password-strength-bar";
import { useForm, useWatch } from "antd/es/form/Form";
import { useDispatch } from "react-redux";
import Api from "../../utils/Axios";
import { setAuthenticated } from "../../features/auth/authSlice";
import AuthLayout from "../../layouts/AuthLayout";
import Guest from "../../components/Middleware/Guest";

export default function Register() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [registerForm] = useForm();
  const [searchParams] = useSearchParams();
  const password = useWatch("password", registerForm);
  const [disableEmail, setDisableEmail] = useState(false);

  // Handle form submission
  const handleFormSubmit = useCallback(
    async (values) => {
      try {
        setLoading(true);
        const registerValues = {
          ...values,
          ...(searchParams.has("token") &&
            searchParams.get("token") !== "" && {
            token: searchParams.get("token"),
          }),
        };

        const { data, message } = await Api.Post("/register", registerValues);
        dispatch(setAuthenticated(data));
        Notify.success(message);
        window.dataLayer.push({
          'event': 'singup_lead',
          'user_email': values.email,
          'user_name': values.name
        });
        navigate("/create-organization")
      } catch (error) {
        if (error.response && error.response.status === 422) {
          const errors = error.response.data.errors;
          const validationErrors = Object.keys(errors).map((key) => ({
            name: key,
            errors: errors[key],
          }));
          registerForm.setFields(validationErrors);
        } else {
          console.error(error);
          Notify.error("Something went wrong");
        }
      } finally {
        setLoading(false);
      }
    },
    [dispatch, navigate, registerForm, searchParams]
  );

  const autoFill = useCallback(() => {
    if (searchParams.has('email') && searchParams.get('email') !== '') {
      registerForm.setFieldValue('email', searchParams.get('email'))
    }
    if (searchParams.has('token') && searchParams.get('token') !== '') {
      setDisableEmail(true);
    }
  }, [searchParams, registerForm])

  useEffect(() => {
    autoFill()
  }, [autoFill])

  return (
    <Guest>
      <AuthLayout>
        <div className="mt-10 mb-5">
          <Typography.Title
            level={2}
            className="text-left text-dark font-semibold font-poppins"
          >
            Create new account
          </Typography.Title>
          <Typography.Paragraph className="font-poppins font-light">
            Sign Up, Stay Ahead — Begin Your Productivity Journey Today.
          </Typography.Paragraph>
        </div>
        <Form
          layout="vertical"
          className="mt-0 "
          onFinish={handleFormSubmit}
          form={registerForm}
          disabled={loading}
        >
          <Form.Item name="token">
            <Input type="hidden" />
          </Form.Item>
          <div className="">
            <Typography.Paragraph className="font-poppins text-dark text-sm mb-1.5">
              Full Name*
            </Typography.Paragraph>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Please input your full name" }]}
            >
              <Input
                className="w-full px-4 py-3 border rounded-md"
                placeholder="Enter your full name"
                autoComplete={false}
              />
            </Form.Item>
          </div>
          <div className="">
            <Typography.Paragraph className="font-poppins text-dark text-sm mb-1.5">
              Email*
            </Typography.Paragraph>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your work email",
                  type: "email",
                },
              ]}
            >
              <Input
                disabled={disableEmail}
                className="w-full px-4 py-3 border rounded-md"
                placeholder="Enter your work email"
                autoComplete={false}
              />
            </Form.Item>
          </div>
          <div className="">
            <Typography.Paragraph className="font-poppins text-dark text-sm mb-1.5">
              Create Password*
            </Typography.Paragraph>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "Enter password" }]}
            >
              <Input.Password
                placeholder="Enter 8 or more characters"
                className="w-full px-4 py-3 border rounded-md mb-2"
                name="password"
              />
            </Form.Item>

            <PasswordStrengthBar
              className="password-strength-checker"
              password={password}
              barColors={["#ddd", "#ddd", "#ef4836", "#f6b44d", "#53B12F"]}
              scoreWords={[
                "😭",
                "Too weak 😟",
                "Weak 😕",
                "Normal 🙂",
                "Strong 😎",
              ]}
            />
          </div>
          <Form.Item rules={[{ required: true, message: "Please accept the terms and privacy" }]} name="terms" className="mb-0 flex gap-x-3" valuePropName="checked">
            <Checkbox>
              <div className="flex items-center">
                <Typography.Paragraph className="mb-0 font-poppins font-normal inline-block ml-1 mt-1">
                  I agree to the{" "}
                  <Link to="https://workotick.com/terms-of-use/" target="_blank" rel="noopener noreferrer" className="underline">
                    Terms
                  </Link>{" "}
                  and{" "}
                  <Link to="https://workotick.com/privacy-policy/" target="_blank" rel="noopener noreferrer" className="underline">
                    Privacy Policy
                  </Link>
                </Typography.Paragraph>
              </div>
            </Checkbox>
          </Form.Item>
          <Form.Item className="mt-8">
            <Button
              type="primary"
              htmlType="submit"
              className="w-full h-12 bg-primary text-white font-poppins text-medium"
              loading={loading}
            >
              Create my account
            </Button>
          </Form.Item>
        </Form>
        <Typography.Paragraph className="text-center font-poppins font-medium mt-3">
          Already a registered user?{" "}
          <Link to="/login" className="text-secondary underline text-sm mt-1">
            Login
          </Link>
        </Typography.Paragraph>
      </AuthLayout>
    </Guest>
  );
}
