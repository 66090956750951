import React, { useState } from "react";
import MainLayout from "../../layouts/MainLayout";
import Search from "antd/es/input/Search";
import { Button, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import InviteModal from "../../components/Modals/InviteModal";
import TeamTable from "./TeamTable";
import InvitationTable from "./InvitationTable";

export default function Team() {

  const [memberCount, setMemberCount] = useState(0);
  const [invitationCount, setInvitationCount] = useState(0);
  const [activeTab, setActiveTab] = useState("members");
  const [search, setSearch] = useState(null);
  const navigate = useNavigate();
  const [showInviteModal, setShowInviteModal] = useState(false);

  const tabs = [
    {
      label: `Active Members(${memberCount})`,
      key: "members",
      children: (
        <TeamTable
          query={search}
          onCountChange={(count) => setMemberCount(count)}
        />
      ),
    },
    {
      label: `Invites (${invitationCount})`,
      key: "invitations",
      children: (
        <InvitationTable
          query={search}
          onCountChange={(count) => setInvitationCount(count)}
        />
      ),
    },
  ];

  return (
    <MainLayout>
      <div className="py-8 md:p-5 font-poppins">
        <div className="flex items-center gap-x-6 mt-4 mb-7">
          <Button className="w-10 h-10 flex justify-center items-center p-2" onClick={() => navigate(-1)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
              <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
            </svg>
          </Button>
          <h1 className="text-2xl font-semibold">Team Management</h1>
        </div>
        <div className="lg:flex justify-between items-center ">
          <div className="dashboard w-full bg-white p-2 rounded-md relative ">
            <Tabs
              type="card"
              defaultActiveKey={activeTab}
              items={tabs.map((tab) => {
                return {
                  label: (
                    <span className="pl-2 ">
                      <button className="text-xl bg-blue-500 text-white rounded-md text-center font-poppins">
                        {tab.label}
                      </button>
                    </span>
                  ),
                  ...tab,
                };
              })}
              onChange={(key) => setActiveTab(key)}
            />
            <div className="hidden md:flex gap-2 items-center absolute top-2 right-2">
              <div className="w-56 flex-grow">
                <Search
                  placeholder="Search"
                  allowClear
                  size="large"
                  onSearch={value => setSearch(value)}
                />
              </div>
              <div
                className="flex my-2 gap-2 items-center"
                style={{ whiteSpace: "nowrap" }}
              >
                <div>
                  <button
                    className="rounded-md p-2 font-poppins text-white bg-primary"
                    onClick={() => setShowInviteModal(true)}
                  >
                    Add New Member +
                  </button>
                </div>
                <InviteModal onClose={() => setShowInviteModal(false)} open={showInviteModal} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
