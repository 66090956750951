import React, { useState } from "react";
import MainLayout from "../../layouts/MainLayout";
import { LeftOutlined } from "@ant-design/icons";
import { Select, Button } from "antd";
import CSV from "../../assets/download-csv.png";
import { useNavigate } from "react-router-dom";
import ProjectPerformanceTable from "./ProjectPerformanceTable";
import CustomDateRangePicker from "../CustomDateRangePicker";

export default function ProjectPerformance() {
  const navigate = useNavigate();
  const data = [
    {
      name: "Workotic",
      data: [
        { date: "12-12-2023", day: "Mon", timeSpent: "1:30" },
        { date: "13-12-2023", day: "Tue", timeSpent: "2:30" },
        { date: "14-12-2023", day: "Wed", timeSpent: "4:30" },
        { date: "16-12-2023", day: "Fri", timeSpent: "5:30" },
      ],
    },
    {
      name: "Flip Mart",
      data: [
        { date: "12-12-2023", day: "Mon", timeSpent: "1:30" },
        { date: "13-12-2023", day: "Tue", timeSpent: "2:30" },
        { date: "14-12-2023", day: "Wed", timeSpent: "4:30" },
        { date: "18-12-2023", day: "Sun", timeSpent: "2:30" },
      ],
    },
    {
      name: "Native Brains",
      data: [
        { date: "12-12-2023", day: "Mon", timeSpent: "1:30" },
        { date: "17-12-2023", day: "Sat", timeSpent: "2:30" },
        { date: "18-12-2023", day: "Sun", timeSpent: "2:30" },
      ],
    },
    {
      name: "Tele Mart",
      data: [
        { date: "12-12-2023", day: "Mon", timeSpent: "1:30" },
        { date: "14-12-2023", day: "Wed", timeSpent: "4:30" },
        { date: "16-12-2023", day: "Fri", timeSpent: "5:30" },
        { date: "18-12-2023", day: "Sun", timeSpent: "2:30" },
      ],
    },
    {
      name: "Mentorice",
      data: [
        { date: "12-12-2023", day: "Mon", timeSpent: "1:30" },
        { date: "14-12-2023", day: "Wed", timeSpent: "4:30" },
        { date: "17-12-2023", day: "Sat", timeSpent: "2:30" },
        { date: "18-12-2023", day: "Sun", timeSpent: "2:30" },
      ],
    },
    {
      name: "Workotic",
      data: [
        { date: "13-12-2023", day: "Tue", timeSpent: "2:30" },
        { date: "14-12-2023", day: "Wed", timeSpent: "4:30" },
        { date: "17-12-2023", day: "Sat", timeSpent: "2:30" },
        { date: "18-12-2023", day: "Sun", timeSpent: "2:30" },
      ],
    },
    {
      name: "Flip Mart",
      data: [
        { date: "12-12-2023", day: "Mon", timeSpent: "1:30" },
        { date: "13-12-2023", day: "Tue", timeSpent: "2:30" },
        { date: "14-12-2023", day: "Wed", timeSpent: "4:30" },
        { date: "16-12-2023", day: "Fri", timeSpent: "5:30" },
        { date: "17-12-2023", day: "Sat", timeSpent: "2:30" },
        { date: "18-12-2023", day: "Sun", timeSpent: "2:30" },
      ],
    },
    {
      name: "Flip Mart",
      data: [
        { date: "12-12-2023", day: "Mon", timeSpent: "1:30" },
        { date: "13-12-2023", day: "Tue", timeSpent: "2:30" },
        { date: "14-12-2023", day: "Wed", timeSpent: "4:30" },
        { date: "16-12-2023", day: "Fri", timeSpent: "5:30" },
        { date: "17-12-2023", day: "Sat", timeSpent: "2:30" },
        { date: "18-12-2023", day: "Sun", timeSpent: "2:30" },
      ],
    },
  ];
  const [filters, setFilters] = useState({
    department: [],
    name: [],
    project: [],
  });

  const handleChange = (value, type) => {
    setFilters((prevFilters) => ({ ...prevFilters, [type]: value }));
  };

  const clearState = (type) => {
    setFilters((prevFilters) => ({ ...prevFilters, [type]: null }));
  };
  //const filteredData = useFilter(data, filters);

  const departmentOptions = [...new Set(data.map((item) => item.department))];
  const memberOptions = [...new Set(data.map((item) => item.name))];
  return (
    <MainLayout>
      <div className='p-5 py-2'>
        <div className='flex flex-col '>
          <div className='flex flex-row items-center gap-4'>
            <button
              className='p-1 px-2 rounded-md border bg-white'
              onClick={() => navigate("/reports")}>
              <LeftOutlined />
            </button>
            <h1 className='xl:text-2xl text-2xl lg:text-xl my-4 font-semibold font-poppins'>
              Project Performance
            </h1>
          </div>
          <div className='flex gap-2 ml-2 main flex-col'>
            <div className='flex  gap-2 flex-col xl:flex-row justify-end mr-2'>
              <div className='flex gap-2 j'>
                <Select
                  value={filters.name}
                  onChange={(value) => handleChange(value, "name")}
                  mode='multiple'
                  onClear={() => clearState("name")}
                  allowClear='true'
                  placeholder='Project'
                  className='flex-grow w-44  h-fit'
                  options={memberOptions.map((member) => ({
                    value: member,
                    label: member,
                  }))}
                />
                <Select
                  value={filters.department}
                  placeholder='Department'
                  onChange={(value) => handleChange(value, "department")}
                  onClear={() => clearState("department")}
                  allowClear='true'
                  className='flex-grow w-44  h-fit'
                  options={departmentOptions.map((department) => ({
                    value: department,
                    label: department,
                  }))}
                />
                <Select
                  placeholder='Member'
                  value={filters.project}
                  mode='multiple'
                  onChange={(value) => handleChange(value, "project")}
                  onClear={() => clearState("project")}
                  className='flex-grow w-44 h-fit'
                  allowClear='true'
                  options={[{
                    label: "Usama",
                    value: "usama"
                  }, {
                    label: "Batool",
                    value: "batool",
                  }, {
                    label: "Yasir",
                    value: "yasir"
                  }]}
                />
              </div>
              <div className='flex  h-8 flex-row gap-2 md:justify-end'>
                <div className='flex gap-2 '>
                  <CustomDateRangePicker />
                </div>
                <div>
                  <Button className='md:flex gap-2 h-8 items-center hidden bg-white'>
                    <img
                      src={CSV}
                      alt='download-icon'
                    />
                    <p className='text-[#9DA5B5]'>CSV</p>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ProjectPerformanceTable projectData={data} />
      </div>
    </MainLayout>
  );
}
