import { Button, Modal, message as Notify } from "antd";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import Api from "../../utils/Axios";

export default function ProjectStatusModal({ open, onClose, project }) {
  const { activeOrganization } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);
      let url = `organization/${activeOrganization?.id}/project/${project?.id}/${!project?.archived ? 'archive' : 'restore'}`;
      const { message } = await Api.Get(url);
      Notify.success(message);
      onClose(true);
    } catch (error) {
      if (error.response) {
        if (error.response.status >= 400) {
          Notify.error(error.response.data.message);
        }
      } else {
        Notify.error('Something went wrong');
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [project, activeOrganization?.id, onClose]);

  return (
    <Modal open={open} onCancel={onClose} footer={false} centered>
      <div className="md:m-10 flex flex-col items-center text-center font-poppins">
        <p className="font-slightly-bold text-2xl my-2">
          Are you sure you want to {!project?.archived ? 'archive' : 'restore'} {project?.name}?
        </p>
        <p className="text-smtext font-slightly-bold">
          {
            !project?.archived
              ? "Users will no longer be able to log time against this project. There will be no impact on the reports or timesheets."
              : "Users can still log time against this project. Reports and timesheets will be impacted."
          }
        </p>
        <div className="flex gap-2">
          <Button
            type="primary"
            className="bg-primary w-40 h-9 mt-5 rounded-sm  font-poppins"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="bg-primary  h-9 mt-5 rounded-sm w-40  font-poppins "
            onClick={handleDelete}
            loading={loading}
          >
            Confirm {!project?.archived ? 'Archive' : 'Restore'}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
