import React, { useCallback, useEffect, useState } from "react";
import Icon1 from "../../assets/member-1.svg";
import Icon3 from "../../assets/member-3.png";
import Icon2 from "../../assets/redicon.png";
import { Drawer, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import useScreenSize from "../../hooks/useScreenSize";
import Screenshot from "../Screenshots/Screenshot";
import { useSelector } from "react-redux";
import { getFormattedTimestamp, secondsToHHMM } from "../../utils/helpers";
import LazyLoad from "../LazyLoad";
import TImesheetDrawerLoader from "../Skeletons/TImesheetDrawerLoader";
import Api from "../../utils/Axios";
import DetailModal from "../Screenshots/DetailModal";


export default function TimeSheetDrawer({
  open,
  onClose,
  worklog,
  loading,
}) {
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedScreenshot, setSelectedScreenshot] = useState(undefined);
  const [screenshots, setScreenshots] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState(undefined);
  const [screenshotLoading, setScreenshotLoading] = useState(false);
  const { isMobile } = useScreenSize();
  const { activeOrganization } = useSelector((state) => state.auth);

  const handleLoadMore = useCallback(async () => {
    try {
      setScreenshotLoading(true);
      const { data: { screenshots: { data, meta } } } = await Api.Post(nextPageUrl, {
        date: worklog?.date,
        project_id: worklog?.project.id,
        users: worklog?.user_ids ?? [],
        departments: worklog?.department_ids ?? []
      });
      setScreenshots((prev) => [...prev, ...data]);
      setNextPageUrl(meta.next_page)
    } catch (error) {
      console.error(error)
    } finally {
      setScreenshotLoading(false);
    }
  }, [nextPageUrl, worklog]);

  const handleSlider = useCallback((type) => {
    const index = screenshots.findIndex(item => item.id === selectedScreenshot?.id);

    if (index > -1) {
      let newScreenshot;
      if (type === 'next') {
        newScreenshot = screenshots[index + 1];
      } else {
        newScreenshot = screenshots[index - 1];
      }
      if (newScreenshot) {
        setSelectedScreenshot(newScreenshot)
      }
    }
  }, [selectedScreenshot, screenshots])

  const handleShowScreenshot = useCallback((screenshot) => {
    setSelectedScreenshot(screenshot);
    setShowDetailModal(true);
  }, [])

  useEffect(() => {
    if (worklog) {
      setScreenshots(worklog.screenshots.data);
      setNextPageUrl(worklog.screenshots.meta.next_page);
    }
  }, [worklog])

  return (
    <Drawer
      placement='right'
      closable={false}
      onClose={onClose}
      open={open}
      width={isMobile ? "100%" : "50%"}>
      <LazyLoad loading={loading} loader={<TImesheetDrawerLoader />}>
        <div>
          <div className='grid grid-cols-4 lg:grid-cols-3 xl:grid-cols-4  justify-center items-center font-poppins sticky top-[-16px] bg-white z-10'>
            <div className='flex gap-2 col-span-4 md:col-span-1  lg:col-span-3 xl:col-span-1'>
              <button onClick={onClose}>
                <LeftOutlined />
              </button>
              <h1 className='text-[#263238] text-2xl'>Worklog</h1>
            </div>
            <div className='flex gap-2 col-span-2 my-3 md:col-span-1'>
              <div>
                <img
                  src={Icon1}
                  alt='icon'
                  className='w-11'
                />
              </div>
              <div>
                <p className='text-sm'>Date</p>
                <p className='font-semibold'>
                  {getFormattedTimestamp(worklog?.date, 'UTC', "Do MMM, YYYY")}
                </p>
              </div>
            </div>
            <div className='flex gap-2 mx-auto col-span-2 my-3 md:col-span-1'>
              <div>
                <img
                  src={Icon2}
                  alt='icon'
                  className='w-11'
                />
              </div>
              <div>
                <p className='text-sm'>Project</p>
                <p className='font-semibold'>{worklog?.project?.name}</p>
              </div>
            </div>
            <div className='flex gap-2 col-span-2 md:col-span-1 my-3'>
              <div>
                <img
                  src={Icon3}
                  alt='icon'
                  className='w-11'
                />
              </div>
              <div>
                <p className='text-sm'>Total Hours</p>
                <p className='font-semibold'>{secondsToHHMM(worklog?.total_hours)} Hrs</p>
              </div>
            </div>
          </div>
          <div className='p-4'>
            <div className="grid grid-cols-3 gap-2">
              {
                screenshots.map(screenshot => (
                  <div className="col-span-1 md:col-span-1">
                    <Screenshot screenshot={screenshot} timezone={activeOrganization.timezone} onClick={() => handleShowScreenshot(screenshot)} />
                  </div>
                ))
              }
            </div>
            {
              nextPageUrl && (
                <div className='flex justify-center mt-2 sticky bottom-[-15px] py-3'>
                  <Button
                    type='primary'
                    className='bg-primary font-poppins mx-auto'
                    onClick={handleLoadMore}
                    loading={screenshotLoading}
                  >
                    Load More
                  </Button>
                </div>
              )
            }
          </div>
        </div>
      </LazyLoad>
      <DetailModal
        onClose={() => setShowDetailModal(false)}
        open={showDetailModal}
        screenshot={selectedScreenshot}
        onNext={() => handleSlider('next')}
        onPrev={() => handleSlider('prev')}
      />
    </Drawer>
  );
}
