import { Table } from 'antd';
import React from 'react'

export default function ActiveTimeTable({data}) {
  const columns = [

    {
      title:  "Member",
      dataIndex: 'user',
      align: 'center',
      render: (user) => (
        <div className='flex items-center gap-2  md:w-32  mx-auto'>
          <img src={user.image} className='w-14' alt='profile' />
          <div>
            <p className='font-semibold text-left w-20'>{user.name}</p>
          </div>
       </div>
       )
    },
    {
      title: "Clock-in",
      dataIndex: 'clockin',
      align: 'center',  
    },
    {
      title: "Active Time (hrs)",
      dataIndex: 'active',
      align: 'center',  
    },
    {
      title: "Log Time (hrs)",
      dataIndex: 'log',
      align: 'center',  
    },
    {
      title: "Clock out",
      dataIndex: 'clockout',
      align: 'center',  
    },
    {
      title: "Remaining Time (hrs)",
      dataIndex: 'remaining',
      align: 'center',  
    },
  ];
  const getTableData = () => {
    const tableData = [];
    data.forEach((item, index) => {
      tableData.push({
        key: `${item.name}-${index + 1}`,
        active: item.active,
        log: item.log,
        clockin: item.clockin,
        clockout: item.clockout,
        remaining: item.remaining,
        user: { name: item.name, image: item.photo},
      });
    });
    return tableData;
  };

  return (
    <div className='overflow-auto bg-white rounded-md p-2 mt-3' >
       <Table
        dataSource={getTableData()}
        columns={columns}
        pagination={true}
        className='m-2'
      />
    </div>
  )
}
