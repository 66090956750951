import React, { useCallback, useEffect, useState } from 'react';
import { Link, createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import workotic from "../assets/workotic-logo.svg";
import { message } from 'antd';
import Api from '../utils/Axios';


export default function GetStarted() {

  const [email, setEmail] = useState(null);
  const [checking, setChecking] = useState(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const checkEmailStatus = useCallback(async () => {
    if (!email) return
    try {
      const { data } = await Api.Post('/check-email', {
        email
      });

      setTimeout(() => {
        navigate({
          pathname: data.registered ? '/login' : '/register',
          search: createSearchParams({
            email,
            step: 'get-started'
          }).toString()
        })
      }, 5000)
    } catch (error) {
      console.error(error);
      message.error('Something get wrong');
    } finally {
      setChecking(false)
    }
  }, [email, navigate])

  useEffect(() => {
    if (searchParams) {
      setEmail(searchParams.get('email'))
    }
  }, [searchParams])

  useEffect(() => {
    checkEmailStatus()
  }, [checkEmailStatus])

  return (
    <div className='h-screen py-5'>
      <Link to='/' className='my-5 inline-block mx-auto w-full'>
        <img
          src={workotic}
          alt='workotic-logo'
          className='mx-auto'
        />
      </Link>
      <div className="text-center">
        <h1 className='text-2xl mb-5'>Start your journey</h1>
        <p className='text-lg'>
          <span className='font-semibold'>Email</span>: {email}
        </p>
        <p className='text-lg sf-get-started-loading'>
          {
            checking ? "Checking status" : "Checking complete. Redirecting"
          }
        </p>
      </div>
    </div>
  )
}
