import { configureStore } from '@reduxjs/toolkit';
import authSlice from './features/auth/authSlice';
import globalSlice from './features/globalSlice';

export default configureStore({
  reducer: {
    auth: authSlice,
    global: globalSlice
  },
});
